import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import SwitchWrapper from "../../../../../../../Components/FormComponents/SwitchWrapper";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

const EditQaQueueQuestion = ({ question, editQuestion }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" onClick={handleClickOpen}>
        Edit Q
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Edit Question This</DialogTitle>

        <Formik
          initialValues={{
            id: question.id,
            question: question.question,
            group: question.group,
            auto_fail: question.auto_fail,
            call_back_request: question.call_back_request,
            compliance: question.compliance,
            product_knowledge: question.product_knowledge,
            professionalism: question.professionalism,
            sales_skills: question.sales_skills,
            tcf: question.tcf,
            data_capture: question.data_capture,
            updated_reason: "Update Question",
          }}
          validationSchema={Yup.object({
            question: Yup.string().required("Question is required"),
            group: Yup.string().required("Group is required"),
          })}
          onSubmit={(values) => {
            editQuestion.mutate(values);

            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Typography align="center" variant="body1" color="warning.main">
                  This Question will only be in this QA Queue
                </Typography>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="question"
                    label="Question"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                  />
                  <SelectWrapper
                    name="group"
                    label="Group"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    options={[
                      { value: "Call", label: "Call" },
                      { value: "Client Info", label: "Client Info" },
                      { value: "Confirmation", label: "Confirmation" },
                      { value: "Opinion", label: "Opinion" },
                      { value: "Amendment", label: "Amendment" },
                      { value: "Query", label: "Query" },
                      { value: "Quote", label: "Quote" },
                    ]}
                  />
                </Stack>

                <Stack spacing={1} sx={{ mt: 2 }}>
                  <SwitchWrapper
                    name="auto_fail"
                    label="Auto Fail"
                    helperText="If the selected answer to this question falls under the fail
                    conditions, the call/evaluation will be marked as a fail."
                  />

                  <SwitchWrapper
                    helperText=" Mark this question as a Data Capture question."
                    name="data_capture"
                    label="Data capture"
                  />

                  <SwitchWrapper
                    name="call_back_request"
                    label="Call Back Request"
                    helperText="Mark this question as a Call Back Request question. If
                    Failed the Evaluation will be marked as a Call Back Request."
                  />

                  <Divider />

                  <Typography variant="caption">
                    Tag this question with the following skills.
                  </Typography>

                  <SwitchWrapper name="compliance" label="Compliance" />
                  <SwitchWrapper
                    name="product_knowledge"
                    label="Product Knowledge"
                  />
                  <SwitchWrapper
                    name="professionalism"
                    label="Professionalism"
                  />

                  <SwitchWrapper name="sales_skills" label="Sales Skills" />
                  <SwitchWrapper name="tcf" label="tcf Skills" />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EditQaQueueQuestion;
