import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchTeamMembers,
  fetchDeactivatedTeamMembers,
  addTeamMember,
} from "../Services/api";
import { useParams } from "react-router-dom";
import AddMemberModal from "./AddMemberModal";
import DeactivateModal from "./DeactivateModal";
import ReactivationModal from "./ReactivationModal";
import { Grid, Alert, LinearProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import withAuth from "../../../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import axios from "axios";
import EditTeamMember from "./EditTeamMember";

const TeamList = () => {
  const getAllUserswithModule = useQuery({
    queryKey: ["Notificatinsgroup"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/dd`
      ),
  });

  const { id: campaignId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpen2, setModalOpen2] = React.useState(false);
  const [reactivationModalOpen, setReactivationModalOpen] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showDeactivated, setShowDeactivated] = React.useState(false);

  const { data: teamMembers = [], refetch: refetchActive } = useQuery(
    ["teamMembers", campaignId],
    () => fetchTeamMembers(campaignId).then((res) => res.data.data),
    { enabled: !!campaignId, refetchOnWindowFocus: false }
  );

  const { data: deactivatedMembers = [] } = useQuery(
    ["deactivatedTeamMembers", campaignId],
    () => fetchDeactivatedTeamMembers(campaignId).then((res) => res.data.data),
    { enabled: !!campaignId, refetchOnWindowFocus: false }
  );

  const addMemberMutation = useMutation(
    (data) => addTeamMember(campaignId, data),
    {
      onSuccess: () => {
        refetchActive();
        setModalOpen(false);
      },
      onError: (error) => {
        console.error("Error adding member:", error);
      },
    }
  );

  const editMember = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/teammembers/${campaignId}/EditTeamMember/${data.id}`,
        {
          user_type: data.user_type,
          languages: data.languages,
        }
      ),
    onSuccess: () => {
      refetchActive();
    },
    onError: (error) => {
      console.error("Error editing team member:", error);
    },
  });

  const handleStatusToggle = (row) => {
    setSelectedRow(row);
    if (row.active) {
      setModalOpen2(true);
    } else {
      setReactivationModalOpen(true);
    }
  };

  const handleAddMember = (data) => {
    addMemberMutation.mutate(data);
  };

  const columns = [
    { field: "email", headerName: "Email", width: 250 },
    { field: "user_type", headerName: "User Type", width: 250 },
    {
      field: "languages",
      headerName: "Languages",
      width: 150,
      valueGetter: (params) => params.row.languages || "N/A",
    },
    {
      field: "active",
      headerName: "Delete",
      width: 150,
      renderCell: (params) => (
        <Button onClick={() => handleStatusToggle(params.row)}>
          {params.row.active ? (
            <DeleteIcon color="error" />
          ) : (
            <RestoreFromTrashIcon color="success" />
          )}
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <EditTeamMember
          existingMembers={teamMembers}
          editMember={editMember}
          row={params.row}
        />
      ),
    },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Typography variant="h6" sx={{ ml: 2, mt: 4 }}>
        List of Team Members
      </Typography>

      {getAllUserswithModule.isLoading ? (
        <LinearProgress />
      ) : (
        <Button
          onClick={() => setModalOpen(true)}
          sx={{ mt: 1, width: "100%", ml: 2 }}
        >
          Add Member
        </Button>
      )}

      {getAllUserswithModule.isError && (
        <Alert severity="error">
          Failed to Load Users. Contact Administrator.
        </Alert>
      )}

      <Box sx={{ height: 600, width: "100%", mt: 2, ml: 2 }}>
        <DataGridPremium
          rows={teamMembers}
          columns={columns}
          getRowId={(row) => row.id}
        />
      </Box>

      <Button
        onClick={() => setShowDeactivated(!showDeactivated)}
        sx={{ mt: 2, width: "100%", ml: 2 }}
        color="error"
      >
        {showDeactivated
          ? "Hide Deactivated Members"
          : "Show Deactivated Members"}
      </Button>

      {showDeactivated && (
        <Box sx={{ height: 600, width: "100%", mt: 2, ml: 2 }}>
          <Typography variant="h6">Deactivated Members</Typography>
          <DataGridPremium
            rows={deactivatedMembers}
            columns={columns}
            getRowId={(row) => row.id}
          />
        </Box>
      )}

      {getAllUserswithModule.isLoading ? (
        <LinearProgress />
      ) : (
        <AddMemberModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          users={getAllUserswithModule?.data?.data?.data || []}
          onSave={handleAddMember}
          existingMembers={teamMembers}
          deactivatedexistingmember={deactivatedMembers}
        />
      )}

      <DeactivateModal
        open={modalOpen2}
        onClose={() => setModalOpen2(false)}
        row={selectedRow}
        campaignId={campaignId}
      />

      <ReactivationModal
        open={reactivationModalOpen}
        onClose={() => setReactivationModalOpen(false)}
        row={selectedRow}
        campaignId={campaignId}
      />

      <AlertPopup
        open={addMemberMutation.isSuccess}
        severity="success"
        message="Team Member added successfully"
      />
      <AlertPopup
        open={addMemberMutation.isError}
        severity="error"
        message="Error adding Team Member"
      />

      <AlertPopup
        open={editMember.isSuccess}
        message="Team member updated successfully"
        severity="success"
      />
      <AlertPopup
        open={editMember.isError}
        message="Error updating team member"
        severity="error"
      />
    </Grid>
  );
};

export default withAuth(TeamList);
