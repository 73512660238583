import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";

import { Stack } from "@mui/system";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

const CreateDataCaptureEmail = ({ createDataCapEmail, data, id }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = ({ values }) => {
    createDataCapEmail.mutate(values, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Add New Email Address
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Add New Email Address</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              queueId: id,
              email: data?.email || "",
            }}
            onSubmit={(values) => {
              handleSubmit({ values });
            }}>
            {() => {
              return (
                <Form>
                  <Stack sx={{ my: 5 }}>
                    <TextfieldWrapper
                      name="email"
                      label="Email Address"
                      type="email"
                    />
                  </Stack>
                  <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateDataCaptureEmail;
