import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import withAuth from "../../../../../../../Hoc/withAuth";

import DeleteDataCaptureUser from "./DeleteDataCaptureUser";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import AddDataCaptureUser from "./AddDataCaptureUser";
import { Stack, Typography } from "@mui/material";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";

const DataCaptureList = ({ user }) => {
  const { id: campaignId } = useParams();

  const getDataCaptureUsers = useQuery({
    queryKey: ["dataCaptureUsers"],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/${campaignId}/dataCapture`
      );
      return response.data.data;
    },
  });

  const createDataCaptureUser = useMutation({
    mutationFn: async (data) => {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/${campaignId}/dataCapture`,
        data
      );
    },

    onSuccess: () => {
      getDataCaptureUsers.refetch();
    },
  });

  const removeDataCaptureList = useMutation({
    mutationFn: async (email) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/${campaignId}/dataCapture/${email}`
      );
    },
    onSuccess: () => {
      getDataCaptureUsers.refetch();
    },
  });

  const columns = [
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "active",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <DeleteDataCaptureUser
          user={params.row.email}
          removeDataCaptureList={removeDataCaptureList}
        />
      ),
    },
  ];

  const rows = getDataCaptureUsers?.data?.map((agent, index) => ({
    ...agent,
    id: agent.id || index,
  }));

  return (
    <div>
      <Typography variant="h6" sx={{ my: 2 }} color="GrayText">
        Data Capture Users List
      </Typography>

      <AddDataCaptureUser createDataCaptureUser={createDataCaptureUser} />
      <Typography variant="h6" sx={{ my: 2 }} color="GrayText">
        List of Email Addresses who will receive the data capture form data
      </Typography>
      <Stack sx={{ height: "90vh" }}>
        {rows && rows.length > 0 && (
          <DataGridPremium
            columns={columns}
            rows={rows}
            getRowId={(row) => row.id}
          />
        )}
      </Stack>
      <AlertPopup
        open={createDataCaptureUser.isError}
        severity="error"
        message={createDataCaptureUser.error?.response?.data?.message}
        onClose={() => createDataCaptureUser.reset()}
      />

      <AlertPopup
        open={removeDataCaptureList.isError}
        severity="error"
        message={removeDataCaptureList.error?.response?.data?.message}
        onClose={() => removeDataCaptureList.reset()}
      />

      <AlertPopup
        open={removeDataCaptureList.isSuccess}
        severity="success"
        message="User removed successfully"
        onClose={() => removeDataCaptureList.reset()}
      />

      <AlertPopup
        open={createDataCaptureUser.isSuccess}
        severity="success"
        message="User added successfully"
        onClose={() => createDataCaptureUser.reset()}
      />
    </div>
  );
};

export default withAuth(DataCaptureList);
