import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Info from "@mui/icons-material/Info";
import withAuth from "../../../../../../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";

import {
  Stack,
  TextField,
  CardHeader,
  FormControl,
  FormLabel,
  FormControlLabel,
  Tooltip,
  Radio,
  RadioGroup,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from "@mui/material";

const Reinitialize = ({
  callListeningTime,
  user,
  setStart,
  endEvaluation,
  data,
}) => {
  const { id, evalID } = useParams();
  const UserEmail = user?.email;
  const [allQuestions, setAllQuestions] = useState([]);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID", evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/Evaluations/${evalID}`,
        config
      ),
  });

  const oldtime = getEvaluationByID?.data?.data?.data?.call_listening_time || 0;

  const SendEmail = useMutation({
    mutationFn: async () => {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QaInbound/admin/evaluations/${id}/${evalID}/sendemail`,
        {},
        config
      );
    },
  });

  const getActiveQuestionGroups = useQuery({
    queryKey: ["getActiveQuestionGroups", id],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/?active=true&campaignId=${id}`,
        config
      ),
    onSuccess: (data) => {
      let questionGroups = data?.data?.data?.map((group) => {
        let groups = group.question?.map((question) => {
          return {
            ...question,
            answer: {
              comments: question.comments,
              answer_value: question.answer_value,
              text_field_input: question.text_field_input,
              yes_no_value: question.yes_no_value,
              answered: false,
            },
          };
        });
        return {
          ...group,
          question: groups,
        };
      });

      setAllQuestions(questionGroups.sort((a, b) => a.order - b.order));
    },
  });

  const evaluationResults = useQuery({
    queryKey: ["getActiveQuestionGroups", evalID],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/${evalID}/results`,
        config
      );
      return response?.data;
    },
    onSuccess: (data) => {
      let results = data?.data;

      setAllQuestions((prevQuestions) => {
        return prevQuestions.map((group) => ({
          ...group,
          question: group.question.map((question) => {
            let result = results.find((r) => r.question_id === question.id);
            if (result) {
              return {
                ...question,
                answer: {
                  ...question.answer,
                  comments: result.comments,
                  answer_value: result.answer_value,
                  text_field_input: result.text_field_input,
                  data_capture_values: result.data_capture_values,
                  yes_no_value:
                    result.yes_no_value !== null ? result.yes_no_value : null,
                  answered:
                    result.answer_value ||
                    result.text_field_input ||
                    result.yes_no_value,
                },
              };
            }
            return question;
          }),
        }));
      });
    },
  });

  useEffect(() => {
    let isComplete = allQuestions?.every((group) => {
      return group.question.every((question) => {
        return question.answer.answered;
      });
    });

    setComplete(isComplete);
  }, [allQuestions]);

  const handleCompleteEvaluation = () => {
    let data = {
      evaluation_id: evalID,
      email: UserEmail,
      call_listening_time: callListeningTime + oldtime,
      results: allQuestions,
    };

    endEvaluation.mutate(data, {
      onSuccess: () => {
        setStart(false);
        sendEmail(evalID, id);
      },
      onError: (error) => {
        console.error("Failed to complete evaluation:", error);
      },
    });
  };

  const sendEmail = (evalID, id) => {
    SendEmail.mutate({ evalID, id });
  };

  const handleSaveProgress = React.useCallback(() => {
    const data = {
      evaluation_id: evalID,
      results: allQuestions?.map((group) => ({
        ...group,
        question: group.question.map((question) => {
          return {
            ...question,
            answer: {
              ...question.answer,
              answer_value:
                question.answer.answer_value !== null
                  ? question.answer.answer_value
                  : null,
              yes_no_value:
                question.answer.yes_no_value !== null
                  ? question.answer.yes_no_value
                  : null,
              text_field_input:
                question.answer.text_field_input !== null
                  ? question.answer.text_field_input
                  : null,
            },
          };
        }),
      })),
      email: UserEmail,
      call_listening_time: callListeningTime + oldtime,
    };

    endEvaluation.mutate(data, {
      onSuccess: () => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate(`/CDA/inbound/user/`);
          setStart(false);
        }, 1000);
      },
      onError: () => {
        setShowAlert(true);
      },
    });
  }, [
    allQuestions,
    callListeningTime,
    endEvaluation,
    evalID,
    navigate,
    oldtime,
    setStart,
    UserEmail,
  ]);

  // Get failed questions for the dialog
  const getFailedQuestions = () => {
    const failedQuestions = [];

    allQuestions?.forEach((group) => {
      group.question.forEach((question) => {
        // Check Yes/No questions
        if (
          question.question_type === "Yes/No" &&
          question.answer.yes_no_value === false
        ) {
          failedQuestions.push({
            question: question.name,
            answer: "No",
          });
        }
        // Check Score questions
        else if (
          question.question_type === "Score" &&
          question.answer.answer_value < 3
        ) {
          failedQuestions.push({
            question: question.name,
            answer: question.answer.answer_value,
          });
        }
      });
    });

    return failedQuestions;
  };

  const failedQuestions = getFailedQuestions();

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    handleCompleteEvaluation();
    setDialogOpen(false);
  };

  // Trigger save on browser back action (before unload)
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      handleSaveProgress();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleSaveProgress]);

  if (getActiveQuestionGroups.isLoading || evaluationResults.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      {allQuestions?.map((group) => (
        <Stack key={group.id} sx={{ mt: 2 }}>
          <CardHeader title={group.name} subheader={group.group_type} />
          <CardContent>
            {group.question.map((question) => {
              if (question.question_type === "Yes/No") {
                return (
                  <YesNoQuestion
                    key={question.id}
                    question={question}
                    handleSetValues={setAllQuestions}
                  />
                );
              } else if (question.question_type === "Text Input") {
                return (
                  <TextQuestion
                    key={question.id}
                    question={question}
                    handleSetValues={setAllQuestions}
                  />
                );
              } else if (question.question_type === "Data Capture") {
                return (
                  <DataCaptureQuestion
                    key={question.id}
                    question={question}
                    handleSetValues={setAllQuestions}
                  />
                );
              }
              return (
                <ScoreQuestion
                  key={question.id}
                  question={question}
                  handleSetValues={setAllQuestions}
                />
              );
            })}
          </CardContent>
        </Stack>
      ))}

      {complete ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setDialogOpen(true)} // Open dialog on click
          sx={{ mt: 2, width: "100%" }}>
          Submit
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveProgress}
          sx={{ mt: 2, width: "100%" }}>
          Save Progress
        </Button>
      )}

      <AlertPopup
        open={showAlert}
        message="Progress saved successfully"
        severity="success"
        onClose={() => setShowAlert(false)}
      />

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            The following questions have failed and will be submitted:
          </Typography>

          {failedQuestions.length > 0 ? (
            <ul>
              {failedQuestions.map((item, index) => (
                <li key={index}>
                  <Typography variant="body1">
                    <strong>{item.question}</strong>
                    <br />
                    <Typography color={"error"}>
                      Answer:{item.answer}
                    </Typography>
                  </Typography>
                </li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1">
              No failed questions to display.
            </Typography>
          )}

          <Typography variant="body2" color="textSecondary" mt={2}>
            Are you sure you want to submit your evaluation with the failed
            questions listed above?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="error">
            Disagree
          </Button>
          <Button onClick={handleDialogConfirm} color="secondary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withAuth(Reinitialize);

const YesNoQuestion = ({ question, handleSetValues }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;

    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  yes_no_value:
                    inputValue === "Yes"
                      ? true
                      : inputValue === "No"
                      ? false
                      : null,
                  answer_value: inputValue,
                  answered: true,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  const handleCommentChange = (e) => {
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  comments: e.target.value,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{ fontSize: "15px", color: "dark" }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>

            <RadioGroup
              value={question.answer.answer_value || null}
              onChange={handleChange}
              aria-labelledby={`demo-${question.id}`}
              name="radio-buttons-group">
              <FormControlLabel value="100" control={<Radio />} label="Yes" />
              <FormControlLabel value="0" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Stack>
        <TextField
          value={question.answer.comments || ""}
          onChange={handleCommentChange}
          variant="outlined"
          placeholder="Enter your comments"
          fullWidth
          multiline
          rows={3}
          sx={{ mt: 1, width: "100%" }}
        />
      </CardContent>
    </Card>
  );
};

const TextQuestion = ({ question, handleSetValues }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value; // Trim whitespace

    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  text_field_input: inputValue,
                  answer_value: inputValue,
                  // Only mark as answered if there's actual content
                  answered: inputValue !== "",
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{
                        fontSize: "15px",
                        color: "dark",
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>
          </Stack>
          <TextField
            value={question.answer.text_field_input || ""}
            onChange={handleChange}
            variant="outlined"
            placeholder="Enter your response"
            fullWidth
            multiline
            rows={3}
            sx={{ mt: 1, width: "100%" }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

const ScoreQuestion = ({ question, handleSetValues }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  answer_value: inputValue,
                  answered: true,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  const handleCommentChange = (e) => {
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  comments: e.target.value,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{
                        fontSize: "15px",
                        color: "dark",
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>

            <RadioGroup
              onChange={handleChange}
              aria-labelledby={`demo-${question.id}`}
              value={question.answer.answer_value || null}
              row
              name="radio-buttons-group">
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="0=Very Poor"
              />
              <FormControlLabel value={1} control={<Radio />} label="1=Poor" />
              <FormControlLabel value={2} control={<Radio />} label="2=Fair" />
              <FormControlLabel value={3} control={<Radio />} label="3=Good" />
              <FormControlLabel
                value={4}
                control={<Radio />}
                label="4=Very Good"
              />
              <FormControlLabel
                value={5}
                control={<Radio />}
                label="5=Excellent"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <TextField
          value={question.answer.comments || ""}
          onChange={handleCommentChange}
          variant="outlined"
          placeholder="Enter your comments"
          fullWidth
          multiline
          rows={3}
          sx={{ mt: 1, width: "100%" }}
        />
      </CardContent>
    </Card>
  );
};

const DataCaptureQuestion = ({ question, handleSetValues }) => {
  let dataCaptureQuestionAnswer = question.answers || null;

  const [openDataCapture, setOpenDataCapture] = useState(false);

  useEffect(() => {
    if (Number(question.answer.answer_value) < 50) {
      setOpenDataCapture(true);
    }
  }, [question.answer.answer_value]);

  console.log(
    " question?.answer?.data_capture_values",
    question?.answer?.data_capture_values
  );

  const [dataCaptureData, setDataCaptureData] = useState(
    question?.answer?.data_capture_values || [
      {
        fieldName: "",
        from: "",
        to: "",
      },
    ]
  );

  const handleDataCaptureStuff = (values) => {
    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  data_capture_values: dataCaptureData,
                },
              };
            }
            return q;
          }),
        };
      });
    });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    const selectedId = e.target.closest("label")?.getAttribute("id");

    let selectedAnswer = dataCaptureQuestionAnswer.find(
      (answer) => answer.id === selectedId
    );

    if (inputValue < 50) {
      setOpenDataCapture(true);
    } else {
      setOpenDataCapture(false);
    }

    handleSetValues((prev) => {
      return prev.map((group) => {
        return {
          ...group,
          question: group.question.map((q) => {
            if (q.id === question.id) {
              return {
                ...q,
                answer: {
                  ...q.answer,
                  answer_value: inputValue,
                  answered: true,
                  data_capture_values:
                    selectedAnswer.value > 5 ? dataCaptureData : null,

                  auto_fail: selectedAnswer.failEvaluation,
                  sendEmail: selectedAnswer.sendEmail,
                },
              };
            }
            return {
              ...q,
            };
          }),
        };
      });
    });
  };
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormLabel id={`demo-${question.id}`}>
                  {question?.name}
                </FormLabel>
                {question.description && (
                  <Tooltip title={question.description}>
                    <Info
                      color="primary"
                      style={{
                        fontSize: "15px",
                        color: "dark",
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              {question.answer.answered && <DoneAllIcon color="success" />}
            </Stack>
          </Stack>

          <React.Fragment>
            <FormControl>
              <RadioGroup
                onChange={(e) => {
                  handleChange(e);
                }}
                aria-labelledby="demo-radio-buttons-group-label"
                value={question.answer.answer_value || null}
                name="radio-buttons-group">
                {dataCaptureQuestionAnswer?.map((answer, index) => (
                  <FormControlLabel
                    key={index}
                    id={answer.id}
                    value={answer.value}
                    control={<Radio />}
                    label={answer.answer}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </React.Fragment>
        </Stack>

        {openDataCapture && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Field Name"
                  value={dataCaptureData[0]?.fieldName || ""}
                  margin="dense"
                  helperText="Enter the field name e.g. first name"
                  onChange={(e) => {
                    setDataCaptureData((prev) => {
                      return prev.map((item) => {
                        return {
                          ...item,
                          fieldName: e.target.value,
                        };
                      });
                    });
                  }}
                  onBlur={handleDataCaptureStuff}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="From"
                  margin="dense"
                  value={dataCaptureData[0]?.from || ""}
                  name="From"
                  helperText="Enter the value to be replaced e.g. John"
                  onChange={(e) => {
                    setDataCaptureData((prev) => {
                      return prev.map((item) => {
                        return {
                          ...item,
                          from: e.target.value,
                        };
                      });
                    });
                  }}
                  onBlur={handleDataCaptureStuff}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="To"
                  margin="dense"
                  value={dataCaptureData[0]?.to || ""}
                  helperText="Enter the value to replace with e.g. Jane"
                  onChange={(e) => {
                    setDataCaptureData((prev) => {
                      return prev.map((item) => {
                        return {
                          ...item,
                          to: e.target.value,
                        };
                      });
                    });
                  }}
                  onBlur={handleDataCaptureStuff}
                />
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};
