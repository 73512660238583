import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import { Stack } from "@mui/system";
import { TextField, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDataCaptureUser({ createDataCaptureUser }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let [email, setEmail] = useState("");

  const handleSave = () => {
    let data = {
      email,
    };
    createDataCaptureUser.mutate(data, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Data Capture email addresses
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Data Capture Email Address</DialogTitle>
        <DialogContent>
          <Stack sx={{ my: 3 }}>
            <Typography variant="body2">
              This user will receive notifications when a data capture is done.
            </Typography>
            <TextField
              sx={{ mt: 2 }}
              name="email"
              label="Email"
              type={"email"}
              value={email}
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
