import * as React from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Card,
  CardContent,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import CreateTag from "../components/CreateTag";
import EditTag from "../components/EditTag";
import RemoveTag from "../components/RemoveTag";

export default function TagManagementPage() {
  const [searchQuery, setSearchQuery] = React.useState("");

  const { data: tags, refetch } = useQuery({
    queryKey: ["tags"],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/tags/QuestionTags`
      );
      return response?.data?.data || [];
    },
  });

  const createTag = useMutation({
    mutationFn: async (data) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/tag`,
        data
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const editTag = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${data.id}/tag`,
        data
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const removeTag = useMutation({
    mutationFn: async (data) =>
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${data.id}/tag`,
        data
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const filteredTags = React.useMemo(() => {
    const tagsArray = Array.isArray(tags) ? tags : [];
    return searchQuery.trim()
      ? tagsArray.filter((tag) =>
          tag?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : tagsArray;
  }, [tags, searchQuery]);

  return (
    <Stack pt={4} spacing={4}>
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Tag Management
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Create, edit, and manage tags for questions
        </Typography>
      </Box>

      <Paper
        elevation={0}
        sx={{
          backgroundColor: "background.default",
          p: 3,
          borderRadius: 2,
        }}>
        <Stack spacing={3}>
          <CreateTag createTag={createTag} />

          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Existing Tags
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Divider />

                <Stack spacing={2}>
                  {filteredTags.length > 0 ? (
                    filteredTags.map((tag) => (
                      <Paper
                        key={tag?.id}
                        elevation={0}
                        sx={{
                          p: 2,
                          backgroundColor: "background.default",
                          border: "1px solid",
                          borderColor: "divider",
                        }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}>
                          <Typography variant="body1">{tag.name}</Typography>
                          <Stack direction="row" spacing={1}>
                            <EditTag editTag={editTag} tag={tag} />
                            <RemoveTag removeTag={removeTag} tag={tag} />
                          </Stack>
                        </Stack>
                      </Paper>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ textAlign: "center", py: 4 }}>
                      {searchQuery
                        ? "No matching tags found"
                        : "No tags available"}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Paper>
    </Stack>
  );
}
