import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Typography, Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import { useParams } from "react-router-dom";

export default function CreateQuestionGroupForm({ createQuestionGroups }) {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Question Group
      </Button>
      
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create QA Question Group</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              description: "",
              group_type: "",
              campaign_specific: false,
              campaign_id: id,
           
            }}
            onSubmit={(values) => {
              console.log(values);
              createQuestionGroups.mutate(values);
              handleClose();
            }}
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <Stack sx={{ py: 2 }} spacing={2}>
                    <TextfieldWrapper name="name" label="Name" />
                    <TextfieldWrapper name="description" label="Description" />
                    {/* <FormControl fullWidth>
                      <InputLabel>Group Question Type</InputLabel>
                      <Field
                        as={Select}
                        name="group_type"
                        label="Group Question Type"
                        value={values.group_type} 
                        onChange={(event) =>
                          setFieldValue("group_type", event.target.value)
                        } 
                      >
                        <MenuItem value="Score">Score Group</MenuItem>
                        <MenuItem value="Text Input">Text Input Group</MenuItem>
                        <MenuItem value="Yes/No">Yes / No Group</MenuItem>
                      </Field>
                    </FormControl> */}

                    <Box
                      sx={{
                        border: "0.1px solid #ccc",
                        borderRadius: "5px",
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        Mark the Question Group as Campaign Specific
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: "center" }}
                      >
                        <Typography>No</Typography>
                        <Switch
                          checked={values.campaign_specific}
                          onChange={(event) =>
                            setFieldValue("campaign_specific", event.target.checked)
                          }
                        />
                        <Typography>Yes</Typography>
                      </Stack>
                    </Box>
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      cancel
                    </Button>
                    <Button variant="contained" type="submit" color="secondary">
                      save
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
