import {
  Alert,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { MusicVideo, VolumeOff } from "@mui/icons-material";
import ReactAudioPlayer from "react-audio-player";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const CallRecordings = ({ updateCallListeningTime, evaluation,showForm }) => {
  const { evalID } = useParams();
  const [currentPlaybackTimes, setCurrentPlaybackTimes] = useState({});
  const [recordings, setRecordings] = useState([]);
  const audioRefs = useRef({});

  const getCallRecordings = useQuery([`Recordings${evalID}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`
    );
  });

  

  const Recordings = getCallRecordings.data?.data?.data;

  const postRecording = useMutation({
    mutationFn: (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`,
        data
      );
    },
    onSuccess: () => {
      getCallRecordings.refetch();
    },
  });

  const handleFileChange = (event) => {
    const newRecordings = Array.from(event.target.files).filter((file) =>
      file.type.startsWith("audio/")
    );
    setRecordings(newRecordings);
  };

  const handleSave = () => {
    const formData = new FormData();
    recordings.forEach((file) => {
      formData.append("file", file);
    });
    postRecording.mutate(formData);
    setRecordings([]);
  };

  const updateListeningTime = (recordingId, increment) => {
    setCurrentPlaybackTimes((prev) => {
      const newTime = (prev[recordingId] || 0) + increment;
      return {
        ...prev,
        [recordingId]: newTime,
      };
    });
  };

  useEffect(() => {
    const totalTime = Object.values(currentPlaybackTimes).reduce(
      (sum, time) => sum + time,
      0
    );
    // console.log("newTime", totalTime);
    updateCallListeningTime(totalTime);
  }, [currentPlaybackTimes, updateCallListeningTime]);

  const handlePlay = (event, recording) => {
    if (evaluation?.status !== "Busy") {
      event.preventDefault();
      if (audioRefs.current[recording.id]) {
        audioRefs.current[recording.id].audioEl.current.pause();
      }
      return false;
    } else {
      const interval = setInterval(() => {
        updateListeningTime(recording.storage_id, 1);
      }, 1000);
      recording.intervalId = interval;
    }
  };

  if (getCallRecordings.isLoading) {
    return <LinearProgress />;
  };

  return (
    <Box sx={{ my: 2 }}>
      {(evaluation?.status === "New" || evaluation?.status === "Busy") && (
        <Box>
          <input
            accept=".mp3,.wav"
            id="contained-button-file"
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {recordings.length === 0 ? (
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                sx={{ px: 5, py: 2, borderStyle: "dashed", mb: 2 }}
                startIcon={<MusicVideo />}
                fullWidth
                component="span"
              >
                Upload New Call Recording
              </Button>
            </label>
          ) : (
            <Box
              sx={{
                p: 2,
                borderColor: "secondary.main",
                borderWidth: "2px",
                borderStyle: "dashed",
              }}
            >
              <Typography variant="span">
                {recordings.map((file) => file.name)}
              </Typography>
              <Button
                sx={{ px: 5, py: 2, m: 1 }}
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      )}
      {Recordings?.length > 0 && (
        <Card>
          <CardContent>
            <Typography mb={2} variant="h6" gutterBottom>
              Uploaded Recordings
            </Typography>
            {evaluation?.status === "New" || evaluation?.status === "Busy" ? (
              <Alert sx={{ mb: 2 }} severity="warning">
                Please remember to <span style={{ color: "red" }}>SAVE</span>{" "}
                after listening to a recording, even if you haven't answered any
                questions. Avoid listening to a recording and then leaving
                without saving the evaluation, as this will require you to
                listen to the recording again.
              </Alert>
            ) : null}
            <Stack spacing={2}>
              {Recordings.map((recording, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                >
                  {showForm===false && (evaluation.status !=="Failed" && evaluation.status !=="Completed") && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "800px",
                        height: "100%",
                        backgroundColor: "rgba(109, 109, 109, 0.5)",
                        zIndex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VolumeOff sx={{ mr: 1,color:'red' }} />
                      <Typography variant="body2" fontWeight="bold" >
                        Begin Evaluation to listen to the recording
                      </Typography>
                    </Box>
                  )}
                  <ReactAudioPlayer
                    ref={(element) => {
                      if (element) {
                        audioRefs.current[recording.id] = element;
                      }
                    }}
                    style={{
                      width: "800px",
                    }}
                    src={`${process.env.REACT_APP_API_URL}/QAInbound/admin/${recording.id}/recording/download`}
                    controls
                    onPlay={(e) => handlePlay(e, recording)}
                    onPause={() => {
                      clearInterval(recording.intervalId);
                    }}
                    onEnded={() => {
                      clearInterval(recording.intervalId);
                    }}
                  />
                  <Typography>{recording.name}</Typography>
                </Box>
              ))}
            </Stack>
          </CardContent>
        </Card>
      )}

      <AlertPopup
        open={postRecording.isSuccess}
        message="Recording added Successfully"
        severity="success"
      />
      <AlertPopup
        open={postRecording.isError}
        message="Error adding recording"
        severity="error"
      />
    </Box>
  );
};

export default CallRecordings;
