import React from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import SelectLanguage from "../../components/SelectLanguage";

const AddMemberModal = ({
  open,
  onClose,
  users,
  onSave,
  existingMembers,
  deactivatedexistingmember,
}) => {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [userType, setUserType] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("error");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);

  const languages = [
    "English",
    "Afrikaans",
    "isiZulu",
    "SeSotho",
    "Tswana",
    "Sepedi",
    "TshiVenda",
    "isNdebele",
    "siSwati",
    "isiXhosa",
    "XiTsonga",
  ];

  const handleSave = () => {
    console.log("Selected Languages Before Save:", selectedLanguages);
    if (selectedUser && userType) {
      const isUserAlreadyAdded = existingMembers?.some(
        (member) => member?.email === selectedUser?.email
      );
      const isUserAlreadyDeactivated = deactivatedexistingmember?.some(
        (member) => member?.email === selectedUser?.email
      );

      if (isUserAlreadyAdded || isUserAlreadyDeactivated) {
        if (isUserAlreadyAdded) {
          setAlertMessage("User is already part of this campaign.");
        } else {
          setAlertMessage(
            "User is already part of this campaign. They were deactivated."
          );
        }
        setAlertSeverity("error");
        setAlertOpen(true);
        console.log("Alert Opened:", alertMessage);
        return;
      }

      const userTypeMap = {
        10: "QA Analyst",
        20: "Call Center Team Leader",
        30: "Manager",
      };

      const data = {
        user_id: selectedUser?.id,
        email: selectedUser?.email,
        user_type: userTypeMap[userType] || "Unknown",
        languages: selectedLanguages,
        active: true,
      };
      onSave(data, setUserType(""), setSelectedLanguages([]));
      onClose();
    }
  };

  const filteredUsers = (users || []).filter((user) => {
    return !(existingMembers || []).some(
      (existingMember) => existingMember?.email === user?.email
    );
  });

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertMessage("");
    console.log("Alert Closed");
  };

  React.useEffect(() => {
    if (open) {
      setAlertOpen(false);
      setAlertMessage("");
    }
  }, [open, selectedUser]);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <Stack spacing={2}>
            <Typography id="modal-title" variant="h6" component="h2">
              Add Team Member
            </Typography>
            <Autocomplete
              id="user-autocomplete"
              fullWidth
              freeSolo
              options={filteredUsers || []}
              getOptionLabel={(option) =>
                `${option?.name} - ${option?.surname} - ${option?.email}` || ""
              }
              onChange={(event, newValue) => setSelectedUser(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Search Email" />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="select-user-type-label">
                Select User Type
              </InputLabel>
              <Select
                labelId="select-user-type-label"
                id="select-user-type"
                label="Select User Type"
                value={userType}
                onChange={(event) => setUserType(event.target.value)}
                sx={{ mb: 2 }}
              >
                <MenuItem value={10}>QA Analyst</MenuItem>
                <MenuItem value={20}>Call Center Team Leader</MenuItem>
                <MenuItem value={30}>Manager</MenuItem>
              </Select>
              <SelectLanguage
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
                languages={languages}
              />
            </FormControl>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 2 }}
            >
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <AlertPopup
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        onClose={handleAlertClose}
      />
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default AddMemberModal;
