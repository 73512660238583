import {
  Alert,
  Button,
  Card,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getQaQuestion, getQaQueueQuestions } from "./State/QaQuestionsDuck";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import SwitchWrapper from "../../../../../Components/FormComponents/SwitchWrapper";
import DisableQaQueueQuestion from "./Components/QuestionsAnswers/DisableQaQueueQuestion";
import { RESET_QA_QUEUES } from "./State/QaDuck";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import AnswersTable from "./Components/QuestionsAnswers/AnswersTable";
import EditQaQueueQuestion from "./Components/QuestionsAnswers/EditQaQueueQuestion";
import AddAnswerToQaQueueQuestion from "./Components/QuestionsAnswers/AddAnswerToQaQueueQuestion";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../Hoc/withAuth";

const QueueQuestionAndAnswersManage = ({ user }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch({ type: RESET_QA_QUEUES });
    dispatch(getQaQueueQuestions(id));
    dispatch(getQaQuestion());
  }, [dispatch, id]);

  const QaQueueQuestions = useQuery(
    {
      queryKey: ["getQaQueueQuestions", id],
      queryFn: () =>
        axios.get(
          `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        ),
    },
    {
      enabled: !!id,
    }
  );

  const createQaQuestion = useMutation(
    (values) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ),
    {
      onSuccess: (values) => {
        QaQueueQuestions.refetch();
      },
    }
  );
  const ReorderQuestions = useMutation(
    (values) =>
      axios.patch(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ),
    {
      onSuccess: (values) => {
        QaQueueQuestions.refetch();
      },
    }
  );

  const editQuestion = useMutation(
    (values) =>
      axios.patch(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions/${values.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ),
    {
      onSuccess: (values) => {
        QaQueueQuestions.refetch();
      },
    }
  );

  const addAnswer = useMutation(
    (values) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions/${values.question_id}/answers`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        QaQueueQuestions.refetch();
      },
    }
  );

  const editAnswer = useMutation(
    (values) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions/${values.question_id}/answers/${values.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        QaQueueQuestions.refetch();
      },
    }
  );

  let questionData = QaQueueQuestions?.data?.data?.data?.sort(
    (a, b) => a.order - b.order
  );

  const [activeQuestions, setActiveQuestions] = React.useState([]);
  const [disabledQuestions, setDisabledQuestions] = React.useState([]);

  useEffect(() => {
    if (questionData?.length > 0) {
      setActiveQuestions(
        questionData.filter((question) => question.active === true)
      );
      setDisabledQuestions(
        questionData.filter((question) => question.active === false)
      );
    }
  }, [questionData]);

  const [showInactive, setShowInactive] = React.useState(false);

  const total = questionData?.length || 0;

  function changeOrder(row, oldIndex, newIndex) {
    let array = [...row];
    // Validate input
    if (
      oldIndex < 0 ||
      oldIndex >= array?.length ||
      newIndex < 0 ||
      newIndex >= array?.length ||
      oldIndex === newIndex
    ) {
      console.error("Invalid index");
      return array;
    }

    // Remove the element from the old index
    const [item] = array.splice(oldIndex, 1);

    // Insert the element at the new index
    array.splice(newIndex, 0, item);

    // Update the order property
    array.forEach((item, index) => {
      item.order = index;
    });

    return array;
  }

  const [rows, setRows] = React.useState(activeQuestions || []);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setRows(activeQuestions);
  }, [activeQuestions]);

  const [orderChanged, setOrderChanged] = React.useState(false);

  const handleRowOrderChange = (params) => {
    setOrderChanged(true);
    setLoading(true);
    // update rows state change row position on value order
    const newRows = changeOrder(rows, params.oldIndex, params.targetIndex);

    setRows(newRows);
    setLoading(false);
  };

  const onSave = () => {
    setOrderChanged(false);
    ReorderQuestions.mutate(rows);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <Card>
          {row.data_capture === true && (
            <Alert severity="warning">
              This is a Data Capture Question
              <br /> It requires a Data Capture Answer <br />
              Be sure to add a question marked with Data Capture Answer, this is
              to indicate the QA Agent fixed the data capture issue.
            </Alert>
          )}
          <Stack my={2} spacing={2}>
            <AddAnswerToQaQueueQuestion
              data_capture={row.data_capture}
              addAnswer={addAnswer}
              id={row.id}
              queue_id={id}
            />
            <AnswersTable
              editAnswer={editAnswer}
              data_capture={row.data_capture}
              answers={row.qa_queue_question_answers}
              queue_id={id}
              question_id={row.id}
            />
          </Stack>
        </Card>
      );
    },
    [id, addAnswer, editAnswer]
  );

  const getDetailPanelHeight = React.useCallback(() => "auto", []);

  const columns = [
    {
      field: "question",
      headerName: "Question",
      width: 500,
    },

    {
      field: "order",
      headerName: "order",
      width: 150,
    },
    {
      field: "group",
      headerName: "group",
      width: 150,
    },
    {
      field: "Answers Count",
      headerName: "Answers Count",
      width: 120,
      renderCell: (params) => {
        if (params?.row.qa_queue_question_answers?.length >= 1) {
          return params?.row.qa_queue_question_answers?.length;
        } else {
          return <Chip color="error" variant="outlined" label="Add Answers" />;
        }
      },
    },
    {
      field: "Edit Question",
      headerName: "Edit Question",
      width: 150,
      renderCell: (params) => (
        <EditQaQueueQuestion
          editQuestion={editQuestion}
          question={params.row}
          queue_id={params.row.queue_id}
        />
      ),
    },
    {
      field: "Disable Question",
      headerName: "Disable Question",
      width: 150,
      renderCell: (params) => (
        <DisableQaQueueQuestion
          editQuestion={editQuestion}
          question={params.row}
          queue_id={params.row.queue_id}
        />
      ),
    },
  ];

  return (
    <div>
      <AlertPopup
        open={addAnswer.isSuccess}
        message="Questions and Answers Added"
        severity="success"
      />
      {/* <AlertPopup
        open={orderSuccess}
        message="Questions orders updated"
        severity="success"
      /> */}
      <AlertPopup
        open={editQuestion.isSuccess}
        message="Question Updated"
        severity="success"
      />
      <AlertPopup
        open={createQaQuestion.isSuccess}
        message="Question Created"
        severity="success"
      />
      <AlertPopup
        open={editAnswer.isSuccess}
        message="Answers Updated"
        severity="success"
      />
      <Card>
        <Stack
          sx={{ border: 1, p: 2 }}
          display="flex"
          justifyContent="center"
          alignContent="center">
          <Typography gutterBottom variant="h6">
            Questions and Answers
          </Typography>
          <Stack direction="row">
            <CreateNewQuestion
              createQaQuestion={createQaQuestion}
              queue_id={id}
              total={total}
            />
          </Stack>
        </Stack>
      </Card>

      {orderChanged && (
        <Stack my={4}>
          <Button
            onClick={() => onSave()}
            variant="contained"
            color="secondary">
            Save Order
          </Button>
        </Stack>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {activeQuestions && activeQuestions?.length > 0 && (
            <DataGridPremium
              autoHeight
              rowReordering
              loading={loading}
              onRowOrderChange={handleRowOrderChange}
              rows={activeQuestions}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              columns={columns}
            />
          )}
        </>
      )}

      {/* <QueueQuestionAndAnswersReorderList id={id} items={active} /> */}

      <Container>
        <Stack>
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
            }}>
            {showInactive
              ? "Hide Inactive Questions"
              : "Show Inactive Questions"}
          </Button>
        </Stack>
        {showInactive && (
          <DisabledQuestions queue_id={id} questions={disabledQuestions} />
        )}
      </Container>
    </div>
  );
};

export default withAuth(QueueQuestionAndAnswersManage);

const CreateNewQuestion = ({ createQaQuestion, queue_id, total }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Create New Question
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create New Question</DialogTitle>

        <Formik
          initialValues={{
            question: "",
            group: "",
            auto_fail: false,
            call_back_request: false,
            compliance: false,
            product_knowledge: false,
            professionalism: false,
            sales_skills: false,
            data_capture: false,
            tcf: false,
            active: true,
            order: total,
            updated_reason: "Update Question",
          }}
          validationSchema={Yup.object({
            question: Yup.string().required("Question is required"),
            group: Yup.string().required("Group is required"),
          })}
          onSubmit={(values) => {
            createQaQuestion.mutate(values);
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Typography align="center" variant="body1" color="warning.main">
                  This Question will only be in this QA Queue
                </Typography>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="question"
                    label="Question"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                  />
                  <SelectWrapper
                    name="group"
                    label="Group"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    options={[
                      { value: "Call", label: "Call" },
                      { value: "Client Info", label: "Client Info" },
                      { value: "Confirmation", label: "Confirmation" },
                      { value: "Opinion", label: "Opinion" },
                      { value: "Amendment", label: "Amendment" },
                      { value: "Query", label: "Query" },
                      { value: "Quote", label: "Quote" },
                    ]}
                  />
                </Stack>

                <Stack spacing={1} sx={{ mt: 2 }}>
                  <SwitchWrapper
                    name="auto_fail"
                    label="Auto Fail"
                    helperText="If the selected answer to this question falls under the fail
                    conditions, the call/evaluation will be marked as a fail."
                  />

                  <SwitchWrapper
                    helperText=" Mark this question as a Data Capture question."
                    name="data_capture"
                    label="Data capture"
                  />

                  <SwitchWrapper
                    name="call_back_request"
                    label="Call Back Request"
                    helperText="Mark this question as a Call Back Request question. If
                    Failed the Evaluation will be marked as a Call Back Request."
                  />

                  <Divider />

                  <Typography variant="caption">
                    Tag this question with the following skills.
                  </Typography>

                  <SwitchWrapper name="compliance" label="Compliance" />
                  <SwitchWrapper
                    name="product_knowledge"
                    label="Product Knowledge"
                  />
                  <SwitchWrapper
                    name="professionalism"
                    label="Professionalism"
                  />

                  <SwitchWrapper name="sales_skills" label="Sales Skills" />
                  <SwitchWrapper name="tcf" label="tcf Skills" />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

const DisabledQuestions = ({ queue_id, questions }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Disable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions?.map((row) => (
            <TableRow
              key={row?.question}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}>
              <TableCell component="th" scope="row">
                {row.question}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <DisableQaQueueQuestion question={row} queue_id={queue_id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
