import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  FormControl,
  LinearProgress,
  Button,
  Typography,
  Stack,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Box,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form, Field } from "formik";
import withAuth from "../../../../../../../Hoc/withAuth";
import { fetchTeamMembers } from "../../Teams/Services/api";
import ViewEvaluationMetrics from "./viewEvaluationMetrics";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactAudioPlayer from "react-audio-player";

const ViewEvaluations = ({ user }) => {
  const { id, evalID } = useParams();
  const [expanded, setExpanded] = React.useState(false);

  // Fetch all team members
  const { data: teamMembers = [] } = useQuery(
    ["teamMembers", id],
    () => fetchTeamMembers(id).then((res) => res.data.data),
    { enabled: !!id }
  );

  const getCallRecordings = useQuery([`Recordings${evalID}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`
    );
  });

  const Recordings = getCallRecordings?.data?.data?.data;

  console.log("Recordings", Recordings);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  // Fetch all users
  const allUsers = useQuery({
    queryKey: ["all Users"],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/teammembers/inboundAllUsers`,
        config
      ),
  });

  const users = allUsers?.data?.data.data;

  // Get evaluation data
  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID", evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/Evaluations/${evalID}`,
        config
      ),
  });

  const updateEvaluation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/evaluations/${evalID}`,
        data,
        config
      ),
    onSuccess: () => {
      getEvaluationByID.refetch();
    },
  });

  const evaluation = getEvaluationByID?.data?.data?.data;

  if (
    getEvaluationByID.isLoading ||
    allUsers.isLoading ||
    teamMembers.isLoading
  ) {
    return <LinearProgress />;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack spacing={2} sx={{ mt: 1 }}>
      <Typography variant="h6" sx={{ my: 3, pl: 2 }}>
        Evaluation Metrics
      </Typography>
      <Card sx={{ minHeight: "75px" }}>
        <CardContent>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Typography fontSize={20}>Evaluation Details</Typography>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Stack>
        </CardContent>

        <CardContent sx={{ mt: -5 }}>
          <Stack spacing={2}>
            <Formik
              initialValues={{
                call_center_agent:
                  users.find(
                    (user) => user.id === evaluation.call_center_agent
                  ) || null,
                unique_id: evaluation.unique_id || "",
                disposition: evaluation.disposition || "",
                team_member_id:
                  teamMembers.find(
                    (member) => member.user_id === evaluation.team_member_id
                  ) || null,
                evaluation_type: evaluation.evaluation_type || "",
                status: evaluation.status || "Busy",
              }}
              enableReinitialize={true}
              onSubmit={(values) => {
                const updatedValues = {
                  ...values,
                  call_center_agent: values.call_center_agent
                    ? values.call_center_agent.id
                    : null,
                  team_member_id: values.team_member_id
                    ? values.team_member_id.user_id
                    : null,
                };
                updateEvaluation.mutate(updatedValues);
              }}
            >
              {({ setFieldValue, values }) => (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Form>
                    <Stack sx={{ py: 2 }} spacing={2}>
                      {/* Call Center Agent Select */}
                      <Field name="call_center_agent">
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            options={users || []}
                            getOptionLabel={(option) =>
                              `${option.name} - ${option.surname} - ${option.email}`
                            }
                            value={values.call_center_agent || null}
                            onChange={(event, newValue) =>
                              setFieldValue("call_center_agent", newValue)
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Call Center Agent"
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Field>

                      <Field name="unique_id">
                        {({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Unique ID"
                            margin="normal"
                          />
                        )}
                      </Field>

                      <Field name="disposition">
                        {({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Disposition"
                            margin="normal"
                          />
                        )}
                      </Field>

                      <Field name="team_member_id">
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            options={teamMembers || []}
                            getOptionLabel={(option) =>
                              `${option.user_type} - ${option.email}`
                            }
                            value={values.team_member_id || null}
                            onChange={(event, newValue) => {
                              setFieldValue("team_member_id", newValue);
                              if (newValue) {
                                setFieldValue(
                                  "team_member_type",
                                  newValue.user_type
                                );
                              } else {
                                setFieldValue("team_member_type", "");
                              }
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.user_id === value.user_id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Team Member"
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Field>

                      <FormControl sx={{ width: "100%" }} margin="normal">
                        <InputLabel>Evaluation Type</InputLabel>
                        <Field
                          as={Select}
                          name="evaluation_type"
                          label="Evaluation Type"
                        >
                          <MenuItem value="Inbound Call">Inbound Call</MenuItem>
                          <MenuItem value="Outbound Call">
                            Outbound Call
                          </MenuItem>
                        </Field>
                      </FormControl>

                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          type="submit"
                          sx={{ width: 110 }}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </Collapse>
              )}
            </Formik>
          </Stack>
        </CardContent>
      </Card>

      <Card>
        {" "}
        <Typography p={2} variant="h6" gutterBottom>
          Call Recordings
        </Typography>
        {Recordings?.map((recording, index) =>
          recording ? (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
            >
              <ReactAudioPlayer
                style={{ width: "800px" }}
                src={`${process.env.REACT_APP_API_URL}/QAInbound/admin/${recording.id}/recording/download`}
                controls
              />
              <Typography>{recording.name}</Typography>
            </Box>
          ) : (
            <Typography variant="subtitle1" key={index}>
              No Recording
            </Typography>
          )
        )}
      </Card>

      <ViewEvaluationMetrics />

      <AlertPopup
        open={updateEvaluation.isError}
        severity="error"
        message="Error updating evaluation"
      />
      <AlertPopup
        open={updateEvaluation.isSuccess}
        severity="success"
        message="Evaluation updated succesfully"
      />
    </Stack>
  );
};

export default withAuth(ViewEvaluations);
