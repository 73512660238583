import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  Grid,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Chip,
  IconButton,
  Collapse,
  Box,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import withAuth from "../../../../../../../Hoc/withAuth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ResultTable = ({ user }) => {
  const { id, evalID } = useParams();
  const [open, setOpen] = React.useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: evaluationResults } = useQuery({
    queryKey: ["getEvaluationResults", evalID],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/${evalID}/results/one`,
        config
      );
      return response.data;
    },
    onError: (error) =>
      console.error("Error fetching evaluation results:", error),
  });

  console.log("evaluationResults", evaluationResults);

  const getActiveQuestionGroups = useQuery({
    queryKey: ["getActiveQuestionGroups", id, evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/${evalID}?active=true`,
        config
      ),
  });

  const Evaluationsgroups = getActiveQuestionGroups.data?.data?.data || [];

  console.log("Evaluationsgroups", Evaluationsgroups);

  const calculateTotalScoreForGroup = (group) => {
    const scoreQuestionsInGroup =
      evaluationResults?.data?.result_evaluation.filter(
        (question) => question?.result_question
      );

    let totalScore = 0;
    let totalWeight = 0;

    group?.question?.forEach((question) => {
      const result = scoreQuestionsInGroup?.find(
        (r) => r.question_id === question.id
      );
      const answerValue = parseInt(result?.answer_value) || 0;
      const weight = question.value || 1;

      let TotalValues;
      if (question.question_type === "Score") {
        TotalValues = answerValue * 0.2 * weight;
      } else {
        TotalValues = answerValue;
      }

      totalScore += TotalValues;
      totalWeight += weight;
    });

    return {
      totalScore: totalScore.toFixed(2),
      totalWeight: totalWeight.toFixed(2),
    };
  };

  const [groupPagination, setGroupPagination] = React.useState(
    Evaluationsgroups?.map(() => ({ page: 0, rowsPerPage: 5 })) || []
  );

  const handleChangePage = (event, newPage, groupIndex) => {
    setGroupPagination((prev) =>
      prev.map((pagination, index) =>
        index === groupIndex ? { ...pagination, page: newPage } : pagination
      )
    );
  };

  const handleChangeRowsPerPage = (event, groupIndex) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setGroupPagination((prev) =>
      prev.map((pagination, index) =>
        index === groupIndex
          ? { ...pagination, rowsPerPage: newRowsPerPage, page: 0 }
          : pagination
      )
    );
  };

  // console.log("groupPagination", groupPagination);

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <CardHeader title="Evaluation Results" />
          <CardContent>
            <Typography variant="h5">Total Score:</Typography>
            {(() => {
              const totalScoresSum = Evaluationsgroups?.reduce((acc, group) => {
                const { totalScore } = calculateTotalScoreForGroup(group);
                return acc + parseFloat(totalScore) || 0;
              }, 0);

              const totalWeightSum = Evaluationsgroups?.reduce((acc, group) => {
                const { totalWeight } = calculateTotalScoreForGroup(group);
                return acc + parseFloat(totalWeight) || 0;
              }, 0);

              const Score = totalScoresSum;
              const Weight = totalWeightSum;
              const Percent = (Score / Weight) * 100;

              return (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={20} color="primary">
                    {Score.toFixed(2)} / {Weight}
                  </Typography>

                  <Typography
                    fontSize={20}
                    color={Percent >= 80 ? "#4ef037" : "red"}
                  >
                    {Percent.toFixed(0)}%
                  </Typography>
                </Stack>
              );
            })()}

            <Divider style={{ margin: "10px 0" }} />
            {/* Display Question Groups and their Totals */}
            <Grid container spacing={1}>
              {Evaluationsgroups?.map((group, index) => {
                const { totalScore, totalWeight } =
                  calculateTotalScoreForGroup(group);

                return (
                  <Grid item xs={12} key={index}>
                    {index > 0 && <Divider style={{ margin: "5px 0" }} />}

                    <Grid container alignItems="center">
                      <Grid item xs={5}>
                        <Typography variant="body1">{group.name}</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Divider orientation="vertical" flexItem />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1" align="right">
                          {totalScore} / {totalWeight}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Divider style={{ margin: "10px 0" }} />
          </CardContent>
        </CardContent>

        {/* Table for Score Questions */}
        {Evaluationsgroups?.status !== true && (
          <CardContent>
            <div style={{ marginBottom: "20px" }}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {Evaluationsgroups?.map((group, groupIndex) => {
                  const { page, rowsPerPage } = groupPagination[groupIndex] || {
                    page: 0,
                    rowsPerPage: 5,
                  };

                  return (
                    <TableContainer
                      key={groupIndex}
                      sx={{ maxHeight: 440, mb: 4 }}
                    >
                      <Table stickyHeader aria-label="evaluation table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{group.name}</TableCell>
                            <TableCell align="right">Answer</TableCell>
                            <TableCell align="right">Percentage (%)</TableCell>
                            <TableCell align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {group.question
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((q, questionIndex) => {
                              const resultQuestion = q.result_question[0] || {};
                              const answerValue =
                                resultQuestion.answer_value || 0;
                              const questionValue = q.value || 1;

                              let Total;
                              if (q.question_type === "Score") {
                                Total = answerValue * 0.2 * questionValue;
                              } else {
                                Total = answerValue;
                              }
                              // const Total = answerValue * 0.2 * questionValue;

                              const percentage = (
                                (Total / questionValue) *
                                100
                              ).toFixed(0);
                              const uniqueKey = `${groupIndex}-${questionIndex}`;
                              const isOpen = open === uniqueKey;

                              return (
                                <React.Fragment key={q.id}>
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    onClick={() =>
                                      setOpen(isOpen ? null : uniqueKey)
                                    }
                                  >
                                    <TableCell>
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                      >
                                        {isOpen ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell>{q.name}</TableCell>

                                    {q.question_type === "Yes/No" ? (
                                      <TableCell align="right">
                                        {answerValue === "100" ? "Yes" : "No"}
                                      </TableCell>
                                    ) : q.question_type === "Score" ? (
                                      <TableCell align="right">
                                        {Total.toFixed(2)} / {questionValue}
                                      </TableCell>
                                    ) : (
                                      <TableCell align="right">
                                        {answerValue}
                                      </TableCell>
                                    )}

                                    {/* Percentage Cell */}
                                    <TableCell align="right">
                                      {!isNaN(percentage)
                                        ? `${percentage}%`
                                        : "N/A"}
                                    </TableCell>

                                    {/* Status Chip */}
                                    <TableCell align="right">
                                      <Chip
                                        variant="outlined"
                                        label={
                                          isNaN(percentage)
                                            ? "N/A"
                                            : percentage < 80
                                            ? "Fail"
                                            : "Pass"
                                        }
                                        color={
                                          isNaN(percentage)
                                            ? "warning"
                                            : percentage < 80
                                            ? "error"
                                            : "success"
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      colSpan={5}
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                      }}
                                    >
                                      <Collapse
                                        in={isOpen}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={1}>
                                          <Typography
                                            fontSize={17}
                                            fontWeight={5}
                                            color="text.secondary"
                                          >
                                            Question Comments
                                          </Typography>
                                          <Typography>
                                            {resultQuestion.comments ||
                                              "No comments available."}
                                          </Typography>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={group.question.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) =>
                          handleChangePage(event, newPage, groupIndex)
                        }
                        onRowsPerPageChange={(event) =>
                          handleChangeRowsPerPage(event, groupIndex)
                        }
                      />
                    </TableContainer>
                  );
                })}
              </Paper>
            </div>
          </CardContent>
        )}

        {/* Table for Other Questions */}
        {/* {otherQuestions?.length > 0 && (
          <CardContent>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440, mb: 4 }}>
                <Table stickyHeader aria-label="evaluation table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Yes/No Question</TableCell>
                      <TableCell align="right">Answer</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otherQuestions
                      .slice(
                        otherQuestionsPagination.page *
                          otherQuestionsPagination.rowsPerPage,
                        otherQuestionsPagination.page *
                          otherQuestionsPagination.rowsPerPage +
                          otherQuestionsPagination.rowsPerPage
                      )
                      .map((q, index) => {
                        const uniqueKey = `other-${index}`;
                        const isOpen = open === uniqueKey;
                        const response = q.answer_value || "N/A";
                        const status =
                          q.answer_value === "Yes"
                            ? "Pass"
                            : q.answer_value === "No"
                            ? "Fail"
                            : null;
                        const chipColor =
                          status === "Pass"
                            ? "secondary"
                            : status === "Fail"
                            ? "error"
                            : "default";

                        return (
                          <React.Fragment key={q.id}>
                            <TableRow
                              hover
                              onClick={() => setOpen(isOpen ? null : uniqueKey)}
                            >
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                >
                                  {isOpen ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell>{q.result_question.name}</TableCell>
                              <TableCell align="right">
                                {response || "N/A"}
                              </TableCell>
                              <TableCell align="right">
                                <Chip
                                  label={status}
                                  color={chipColor}
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                }}
                              >
                                <Collapse
                                  in={isOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      fontSize={17}
                                      fontWeight={5}
                                      color="text.secondary"
                                    >
                                      Question Comments
                                    </Typography>
                                    <Typography>
                                      {q.comments || "No comments available."}
                                    </Typography>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={otherQuestions.length}
                rowsPerPage={otherQuestionsPagination.rowsPerPage}
                page={otherQuestionsPagination.page}
                onPageChange={handleOtherQuestionsPageChange}
                onRowsPerPageChange={handleOtherQuestionsRowsPerPageChange}
              />
            </Paper>
          </CardContent>
        )} */}

        {/* Table for Text Input Questions */}
        {/* {TextInputQuestions?.length > 0 && (
          <CardContent>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="evaluation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Text Input Questions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TextInputQuestions.slice(
                      textQuestionsPagination.page *
                      textQuestionsPagination.rowsPerPage,
                      textQuestionsPagination.page *
                      textQuestionsPagination.rowsPerPage +
                      textQuestionsPagination.rowsPerPage
                    ).map((q) => {
                      const response = q.answer_value || "N/A";
                      return (
                        <TableRow key={q.id}>
                          <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              {q.result_question.name}
                            </Typography>
                            <Typography variant="body2">{response}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={TextInputQuestions.length} 
                rowsPerPage={textQuestionsPagination.rowsPerPage}
                page={textQuestionsPagination.page}
                onPageChange={handleTextQuestionsPageChange}
                onRowsPerPageChange={handleTextQuestionsRowsPerPageChange}
              />
            </Paper>
          </CardContent>
        )} */}
      </Card>
    </Stack>
  );
};

export default withAuth(ResultTable);
