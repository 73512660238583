import React from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';

const SelectLanguage = ({ 
  languages, 
  selectedLanguages, 
  setSelectedLanguages 
}) => {
  return (
    <Autocomplete
      multiple
      id="languages-selector"
      options={languages || []}
      value={selectedLanguages}
      onChange={(event, newValue) => {
        setSelectedLanguages(newValue);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Select Languages" 
          fullWidth 
        />
      )}
      renderTags={(selected, getTagProps) =>
        selected.map((option, index) => (
          <Chip 
            key={option} 
            label={option} 
            {...getTagProps({ index })} 
          />
        ))
      }
    />
  );
};

export default SelectLanguage;