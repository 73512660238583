import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import * as Yup from "yup";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const EditTag = ({ editTag, tag }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} color="primary">
        Edit
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Tag</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ id: tag.id, name: tag?.name }} 
             validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                        })}
            onSubmit={(values) => {
              editTag.mutate(values); 
              handleClose();
            }}
          >
            {() => {
              return (
                <Form>
                  <Stack p={2} spacing={2}>
                    <TextfieldWrapper
                      name="name"
                      label="Tag Name"
                    />
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="success" variant="contained" type="submit">
                      Save
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <AlertPopup
        open={editTag.isSuccess}
        message="Tag edited successfully"
        severity={"success"}
      />
      <AlertPopup
        open={editTag.isError}
        message="Error editing Tag"
        severity={"error"}
      />
    </React.Fragment>
  );
};

export default EditTag;