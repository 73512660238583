import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useField } from "formik";
import { FormHelperText } from "@mui/material";

export default function SwitchWrapper({
  name,
  label,
  helperText,
  ...otherProps
}) {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <FormGroup>
      <FormControlLabel
        {...configTextfield}
        control={<Switch checked={field.value} />}
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormGroup>
  );
}
