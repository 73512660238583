import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editQaQuestionAnswer } from "../../State/QaQuestionsDuck";

import SliderWrapper from "../../../../../../../Components/FormComponents/SliderWrapper";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

const EditAnswer = ({ answer, data_capture }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Edit Answer
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Edit Answer</DialogTitle>
        <Formik
          initialValues={{
            id: answer.id,
            question_id: answer?.question_id,
            answer_type: answer?.answer_type,
            answer: answer?.answer,
            value: +answer?.value || 0,
            active: true,

            email_on_fail: answer?.email_on_fail || false,

            updated_reason: "Edited Answer",
          }}
          validationSchema={Yup.object({
            answer_type: Yup.string().required("Required"),
            answer: Yup.string().required("Required"),
            value: Yup.number().required("Required"),
          })}
          onSubmit={(values) => {
            values.value = values.value / 10;

            dispatch(editQaQuestionAnswer(values));
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="answer"
                    label="answer"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />

                  <Stack
                    sx={{
                      border: 1,
                      borderColor: "divider",
                      p: 2,
                    }}>
                    <SliderWrapper
                      inputs={{
                        min: 0,
                        max: 10,
                      }}
                      name="value"
                      label="Value"
                      min={0}
                      max={1}
                    />

                    {values.value >= 9 && (
                      <Typography variant="body1" color="secondary">
                        Correct Answer
                      </Typography>
                    )}

                    {values.value >= 6 && values.value <= 8 && (
                      <Typography variant="body1" color="warning.main">
                        Partially Correct Answer (5-7)
                      </Typography>
                    )}

                    {values.value < 6 && (
                      <Typography variant="body1" color="error">
                        Incorrect Answer
                      </Typography>
                    )}

                    <Typography variant="body1">
                      if the value is 8 or above the answer is correct, if the
                      value is 5-7 the answer is partially correct (will
                      decrease the overall score), if the value is below 6 this
                      will fail the question
                    </Typography>
                  </Stack>

                  {data_capture && (
                    <Stack
                      sx={{
                        border: 1,
                        borderColor: "divider",
                        p: 2,
                      }}>
                      <Typography variant="body1">
                        this will mark the answer as an error if the data
                        capture is incorrect.
                      </Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            name="data_capture"
                            label="Data Capture"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            color="error"
                            checked={values.data_capture}
                            onChange={(e) => {
                              setFieldValue("data_capture", e.target.checked);
                            }}
                          />
                        }
                        label="Data Capture Answer Error"
                      />
                    </Stack>
                  )}
                  {data_capture && (
                    <Stack
                      sx={{
                        border: 1,
                        borderColor: "divider",
                        p: 2,
                      }}>
                      <Typography variant="body1">
                        Send a email to the Data Capture team if this answer is
                        selected.
                      </Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            name="email_on_fail"
                            label="Send Email on Fail"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            color="error"
                            checked={values.email_on_fail}
                            onChange={(e) => {
                              setFieldValue("email_on_fail", e.target.checked);
                            }}
                          />
                        }
                        label="Send Email on Fail"
                      />
                    </Stack>
                  )}
                </Stack>
              </DialogContent>
              <Button variant="contained" fullWidth type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EditAnswer;
