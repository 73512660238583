import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Stack } from "@mui/system";

import { IconButton, Typography } from "@mui/material";
import { GridDeleteIcon } from "@mui/x-data-grid-pro";

const DeleteDataCaptureEmail = ({ deleteDataCapEmail, data, id }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    deleteDataCapEmail.mutate(data, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClickOpen}
        size="small"
        color="error"
        aria-label="delete">
        <GridDeleteIcon />
      </IconButton>

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete Email Address</DialogTitle>
        <DialogContent>
          <Stack>
            <Typography variant="h6" gutterBottom>
              Are you sure you want to delete this email?
            </Typography>
            <Typography variant="h6" color="error" gutterBottom>
              {data?.email}
            </Typography>
            <DialogActions>
              <Button onClick={handleClose}>cancel</Button>
              <Button variant="contained" color="error" onClick={handleSubmit}>
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteDataCaptureEmail;
