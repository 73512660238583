import React, { useEffect } from "react";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import {
  Card,
  Typography,
  Stack,
  Chip,
  Tooltip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
} from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import EditQuestions from "../components/EditQuestions";
import DeActivateQuestions from "../components/DeActivateQuestions";
import { Info } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

const SortableItem = ({ question, editQuestionsReq, deactivateQuestions }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: question.id });

  const [view, setView] = React.useState(false);

  return (
    <Card
      key={question.id}
      sx={{
        borderRadius: 1,
        p: 1,
        mb: 1,

        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isDragging ? "grabbing" : "grab",
        pointerEvents: isDragging ? "none" : "auto",
      }}>
      <Stack direction="row">
        <div
          style={{ cursor: "grab", flexGrow: 1 }}
          {...attributes}
          {...listeners}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="caption">{question.order}</Typography>
              <Typography>{question.name}</Typography>
              {question.description && (
                <Tooltip title={question.description}>
                  <Info
                    color="primary"
                    style={{ fontSize: "15px", color: "dark" }}
                  />
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </div>

        <Stack direction="row" spacing={1}>
          <div ref={setNodeRef} {...attributes} {...listeners}>
            {question.question_type === "Score" ? (
              <Chip
                sx={{ flexGrow: 1 }}
                component="div"
                color="success"
                label={`${question.question_type} (${question.value})`}
              />
            ) : (
              <Chip
                sx={{ flexGrow: 1 }}
                component="div"
                color="primary"
                label={question.question_type}
              />
            )}
          </div>
          <div style={{ pointerEvents: "auto" }}>
            <EditQuestions
              question={question}
              editQuestionsReq={editQuestionsReq}
            />
          </div>
          <div style={{ pointerEvents: "auto" }}>
            <DeActivateQuestions
              deactivateQuestions={deactivateQuestions}
              question={question}
            />
          </div>
        </Stack>
      </Stack>
      <Stack>
        <Stack direction={"row"} spacing={1}>
          {question?.tags.map((name, index) => (
            <Typography
              sx={{
                borderRadius: 1,
                bgcolor: "background.paper",

                p: 0.2,
                px: 1,
              }}
              key={index}
              variant="caption">
              {name}
            </Typography>
          ))}
        </Stack>
        {question.question_type === "Data Capture" && (
          <Box sx={{ mt: 1 }}>
            {!view ? (
              <Button
                size="small"
                color="inherit"
                onClick={() => setView(!view)}>
                data Capture Answers
              </Button>
            ) : (
              <Button onClick={() => setView(!view)} size="small">
                close
              </Button>
            )}

            {view && (
              <>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Answer</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Send Email</TableCell>
                        <TableCell>Fail Evaluation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {question?.answers?.map((answer, index) => (
                        <TableRow key={index}>
                          <TableCell variant="caption">{index + 1}</TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {answer.answer}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              size="small"
                              label={answer.value}
                              color={answer.value < 50 ? "error" : "success"}
                            />
                          </TableCell>
                          <TableCell>
                            {answer.sendEmail && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}>
                                <Typography variant="caption">
                                  Send Email
                                </Typography>
                                <EmailIcon fontSize="small" color="primary" />
                              </Stack>
                            )}
                          </TableCell>
                          <TableCell>
                            {answer.failEvaluation && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}>
                                <Typography variant="caption">
                                  Fail Evaluation
                                </Typography>
                                <SmsFailedIcon fontSize="small" color="error" />
                              </Stack>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        )}
      </Stack>
    </Card>
  );
};

const QuestionList = ({ group, editQuestionsReq, deactivateQuestions }) => {
  const [questions, setQuestions] = React.useState(group?.question || []);

  useEffect(() => {
    if (group && group.question) {
      setQuestions(group.question);
    }
  }, [group]);

  const handleOpenSnackbar = (message, severity) => {
    console.log(`${severity.toUpperCase()}: ${message}`);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return;

    if (active.id !== over.id) {
      setQuestions((prevQuestions) => {
        const oldIndex = prevQuestions.findIndex((q) => q.id === active.id);
        const newIndex = prevQuestions.findIndex((q) => q.id === over.id);

        if (oldIndex === -1 || newIndex === -1) {
          console.error("Question not found in the list");
          return prevQuestions;
        }

        const reorderedQuestions = arrayMove(prevQuestions, oldIndex, newIndex);

        const updatedQuestions = reorderedQuestions.map((question, index) => ({
          ...question,
          order: index,
        }));

        updateQuestionsInDatabase(updatedQuestions);

        return updatedQuestions;
      });
    }
  };

  const updateQuestionsInDatabase = async (questions) => {
    console.log("These are the questions", questions);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${group.campaign_id}/group/${group.id}/question/order`,
        {
          updatedQuestions: questions,
        }
      );
      handleOpenSnackbar("Questions reordered successfully!", "success");
    } catch (error) {
      console.error("Error updating questions:", error);
      handleOpenSnackbar("Failed to reorder questions", "error");
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext
        items={questions.map((q) => q.id)}
        strategy={verticalListSortingStrategy}>
        {questions
          .sort((a, b) => a.order - b.order || a.name.localeCompare)
          .map((question) => {
            return (
              <React.Fragment key={question.id}>
                <SortableItem
                  question={question}
                  id={group.id}
                  editQuestionsReq={editQuestionsReq}
                  deactivateQuestions={deactivateQuestions}
                />
              </React.Fragment>
            );
          })}
      </SortableContext>
    </DndContext>
  );
};

export default QuestionList;
