import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Typography,
  Switch,
  Slider,
  Divider,
  Alert,
  Card,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  IconButton,
  TextField,
  Grid,
  Input,
  Container,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import TagSelector from "./TagSelector";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import * as Yup from "yup";
import SelectWrapper from "../../../../../../Components/FormComponents/Select";
import EmailIcon from "@mui/icons-material/Email";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

import DeleteIcon from "@mui/icons-material/Delete";

import EditIcon from "@mui/icons-material/Edit";
import { EditAnswer } from "./CreateQuestion";
import { v4 as uuidV4 } from "uuid";
const EditQuestions = ({ editQuestionsReq, question }) => {
  const [open, setOpen] = React.useState(false);

  const getTags = useQuery({
    queryKey: ["tags"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/tags/QuestionTags`
      ),
  });

  let Tags = getTags?.data?.data?.data || [];

  const [addAnswer, setAddAnswer] = React.useState(false);

  const [newAnswer, setNewAnswer] = React.useState({
    id: uuidV4(),
    answer: "",
    value: 0,
    sendEmail: false,
    failEvaluation: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWheel = (event, setFieldValue, currentValue) => {
    event.preventDefault();
    const newValue = currentValue + (event.deltaY < 0 ? 5 : -5);
    setFieldValue("value", Math.max(0, newValue));
  };

  const handleInputChange = (event) => {
    setNewAnswer({
      ...newAnswer,
      value: event.target.value === "" ? "" : Number(event.target.value),
    });
  };

  const handleBlur = () => {
    if (newAnswer.value < 0) {
      setNewAnswer(0);
    } else if (newAnswer.value > 100) {
      setNewAnswer(100);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        variant="contained"
        onClick={handleClickOpen}
        disabled={!question.active}
        color={question.active ? "primary" : "primary"}>
        <EditIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle> QA Question </DialogTitle>
        <DialogContent>
          <Container>
            <Formik
              initialValues={{
                ...question,
                name: question.name || "",
                description: question.description || "",
                question_type: question.question_type || "",
                value: question.value !== undefined ? question.value : 0,
                notification_group: false,
                answers: question.answers || [],
                auto_fail: false,
                group_id: question.group_id,
                tags:
                  question.tags?.map((tag) => ({
                    id: Tags.find((t) => t.name === tag)?.id,
                    name: tag,
                  })) || [],
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Required"),
                description: Yup.string().required("Required"),
                value: Yup.number().required("Required"),
              })}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("EDIT", values);
                editQuestionsReq.mutate(values);
                handleClose();
              }}>
              {({ setFieldValue, values }) => (
                <Form>
                  <Stack sx={{ py: 2 }} spacing={2}>
                    <SelectWrapper
                      name="question_type"
                      label="Question Type"
                      options={[
                        {
                          value: "Score",
                          label: "Score Question",
                        },
                        {
                          value: "Text Input",
                          label: "Text Input Question",
                        },
                        {
                          value: "Yes/No",
                          label: "Yes/No Question",
                        },
                        {
                          value: "Data Capture",
                          label: "Data Capture Question",
                        },
                      ]}
                    />

                    {values?.question_type === "Data Capture" && (
                      <>
                        <Alert severity="info">
                          <strong>Data Capture Question</strong> — Data Capture
                          Question is a question type where the evaluator can
                          input a field value. the incorrect value and the
                          corrected value.
                        </Alert>
                      </>
                    )}

                    {values?.question_type === "Text Input" && (
                      <Alert severity="info">
                        <strong>Text Input Question</strong> — Text Input
                        Question is a question type where the evaluator can
                        input a text answer.
                      </Alert>
                    )}

                    {values?.question_type === "Score" && (
                      <Alert severity="info">
                        <strong>Score Question</strong> — Score Question is a
                        question type where the evaluator can input a score from
                        0 to 100.
                      </Alert>
                    )}

                    {values?.question_type === "Yes/No" && (
                      <Alert severity="info">
                        <strong>Yes/No Question</strong> — Yes/No Question is a
                        question type where the evaluator can input a Yes or No
                        answer.
                      </Alert>
                    )}

                    <TextfieldWrapper name="name" label="Question" />
                    <TextfieldWrapper
                      name="description"
                      label="Description/Details"
                    />

                    {values?.question_type === "Data Capture" && (
                      <Card sx={{ p: 1 }}>
                        <Typography>Add Data capture answers</Typography>

                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Answer</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Send Email</TableCell>
                                <TableCell>Fail Evaluation</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>delete</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values?.answers?.map((answer, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    <Typography>{answer.answer}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      label={answer.value}
                                      color={
                                        answer.value < 50 ? "error" : "success"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {answer.sendEmail && (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={0.5}>
                                        <Typography variant="body2">
                                          Send Email
                                        </Typography>
                                        <EmailIcon color="primary" />
                                      </Stack>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {answer.failEvaluation && (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={0.5}>
                                        <Typography variant="body2">
                                          Fail Evaluation
                                        </Typography>
                                        <SmsFailedIcon color="error" />
                                      </Stack>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <EditAnswer
                                      answer={answer}
                                      index={index}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() => {
                                        const newAnswers = [...values.answers];
                                        newAnswers.splice(index, 1);
                                        setFieldValue("answers", newAnswers);
                                      }}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {/* {values.answers.map((answer, index) => (
                        <Card key={index} sx={{ my: 1, p: 1 }}>
                          <Stack direction="row" spacing={2}>
                            <Typography>
                              {index + 1}. {answer.answer}
                            </Typography>

                            <Chip
                              label={answer.value}
                              color={answer.value < 50 ? "error" : "success"}
                            />

                            {answer.sendEmail && (
                              <Stack direction="row" alignContent="center">
                                <Typography>Send Email</Typography>
                                <EmailIcon color="primary" />
                              </Stack>
                            )}

                            {answer.failEvaluation && (
                              <Stack direction="row" alignContent="center">
                                <Typography>Fail Evaluation</Typography>
                                <SmsFailedIcon color="error" />
                              </Stack>
                            )}
                          </Stack>
                        </Card>
                      ))} */}

                        {addAnswer ? (
                          <Button
                            sx={{ my: 1 }}
                            variant="contained"
                            color="warning"
                            onClick={() => {
                              setAddAnswer(false);
                              setNewAnswer({
                                id: null,
                                answer: "",
                                value: 0,
                                sendEmail: false,
                                failEvaluation: false,
                              });
                            }}>
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            sx={{ my: 1 }}
                            variant="outlined"
                            onClick={() => setAddAnswer(true)}>
                            Add Answer
                          </Button>
                        )}

                        <>
                          {addAnswer && (
                            <Stack spacing={2}>
                              <TextField
                                label="Answer"
                                value={newAnswer.answer}
                                onChange={(e) =>
                                  setNewAnswer({
                                    ...newAnswer,
                                    answer: e.target.value,
                                  })
                                }
                              />

                              <Box sx={{ p: 2 }}>
                                <Typography id="input-slider" gutterBottom>
                                  Value (0-100) - The value of the answer
                                </Typography>

                                <Grid container>
                                  <Grid item xs={10}>
                                    <Slider
                                      color={
                                        newAnswer.value < 50
                                          ? "error"
                                          : "success"
                                      }
                                      aria-labelledby="input-slider"
                                      defaultValue={0}
                                      valueLabelDisplay="auto"
                                      shiftStep={5}
                                      step={1}
                                      min={0}
                                      max={100}
                                      value={newAnswer.value}
                                      onChange={(e) => {
                                        setNewAnswer({
                                          ...newAnswer,
                                          value: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1}></Grid>
                                  <Grid item xs={1}>
                                    <Input
                                      value={newAnswer.value || 0}
                                      size="small"
                                      onChange={handleInputChange}
                                      onBlur={handleBlur}
                                      inputProps={{
                                        step: 10,
                                        min: 0,
                                        max: 100,
                                        type: "number",
                                        "aria-labelledby": "input-slider",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              {newAnswer.value < 50 && (
                                <>
                                  <Box
                                    sx={{
                                      border: "0.1px solid #ccc",
                                      borderRadius: "5px",
                                      padding: "16px",
                                    }}>
                                    <Typography variant="body1">
                                      Mark as a failed evaluation
                                    </Typography>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      sx={{ alignItems: "center" }}>
                                      <Typography>No</Typography>
                                      <Switch
                                        checked={
                                          newAnswer.failEvaluation || false
                                        }
                                        onChange={(e) =>
                                          setNewAnswer({
                                            ...newAnswer,
                                            failEvaluation: e.target.checked,
                                          })
                                        }
                                      />
                                      <Typography>Yes</Typography>
                                    </Stack>
                                    {newAnswer.failEvaluation && (
                                      <Typography
                                        variant="caption"
                                        color="error">
                                        if this answer is selected the entire
                                        evaluation will be marked as failed.
                                      </Typography>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "0.1px solid #ccc",
                                      borderRadius: "5px",
                                      padding: "16px",
                                    }}>
                                    <Typography color="error">
                                      A Value below 50 is considered a fail
                                      answer
                                    </Typography>

                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignContent={"center"}>
                                      <Typography variant="body1">
                                        Send Data Capture Email Notification
                                      </Typography>
                                      <Button>
                                        view data capture email list
                                      </Button>
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      sx={{ alignItems: "center" }}>
                                      <Typography>No</Typography>
                                      <Switch
                                        checked={newAnswer.sendEmail}
                                        onChange={(e) =>
                                          setNewAnswer({
                                            ...newAnswer,
                                            sendEmail: e.target.checked,
                                          })
                                        }
                                      />
                                      <Typography>Yes</Typography>
                                      <EmailIcon
                                        color={
                                          newAnswer.sendEmail
                                            ? "success"
                                            : "error"
                                        }
                                      />
                                    </Stack>
                                  </Box>
                                </>
                              )}

                              <Button
                                variant="contained"
                                onClick={() => {
                                  setFieldValue("answers", [
                                    ...values.answers,
                                    newAnswer,
                                  ]);
                                  setAddAnswer(false);
                                  setNewAnswer({
                                    answer: "",
                                    value: 0,
                                    sendEmail: false,
                                  });
                                }}>
                                Save Answer
                              </Button>
                            </Stack>
                          )}
                        </>
                      </Card>
                    )}

                    {values?.question_type === "Score" && (
                      <>
                        <Divider />

                        <Typography>
                          Weight (0-100) - The weight of the question in the
                          evaluation
                        </Typography>

                        <Stack direction="row" spacing={2}>
                          <Slider
                            defaultValue={0}
                            valueLabelDisplay="auto"
                            shiftStep={5}
                            step={1}
                            min={0}
                            max={100}
                            value={values.value}
                            onChange={(event) => {
                              setFieldValue("value", event.target.value);
                            }}
                          />

                          <TextfieldWrapper
                            name="value"
                            type="number"
                            label="Weight (number)"
                            step={5}
                            min={0}
                            onWheel={(event) =>
                              handleWheel(event, setFieldValue, values.value)
                            }
                            disabled
                          />
                        </Stack>
                      </>
                    )}

                    {/* Notification Group Section */}
                    {values?.question_type === "Yes/No" && (
                      <>
                        <Box
                          sx={{
                            border: "0.1px solid #ccc",
                            borderRadius: "5px",
                            padding: "16px",
                            mt: 2,
                          }}>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            Mark as notification Question
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: "center" }}>
                            <Typography>No</Typography>
                            <Switch
                              checked={values.notification_group}
                              onChange={(event) =>
                                setFieldValue(
                                  "notification_group",
                                  event.target.checked
                                )
                              }
                            />
                            <Typography>Yes</Typography>
                          </Stack>
                          <Typography variant="caption" color="textSecondary">
                            If the question is failed, a notification will be
                            sent to the marked Team Leaders/Managers (whoever is
                            in the notification fields).
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            border: "0.1px solid #ccc",
                            borderRadius: "5px",
                            padding: "16px",
                          }}>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            Mark as an Auto Fail question
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: "center" }}>
                            <Typography>No</Typography>
                            <Switch
                              checked={values.auto_fail}
                              onChange={(event) =>
                                setFieldValue("auto_fail", event.target.checked)
                              }
                            />
                            <Typography>Yes</Typography>
                          </Stack>
                          <Typography variant="caption" color="textSecondary">
                            If the question is marked as Auto Fail, a failed
                            status for the evaluation will be updated if the
                            question answer is no.
                          </Typography>
                        </Box>
                      </>
                    )}

                    {Tags && Tags.length > 0 && (
                      <>
                        <Divider />
                        <Typography variant="caption">
                          Tag the question with appropriate tags to get stats on
                          the agents performance/behavior
                        </Typography>
                        <TagSelector
                          Tags={Tags}
                          value={values.tags}
                          onChange={(newValue) =>
                            setFieldValue("tags", newValue)
                          }
                        />
                      </>
                    )}
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" type="submit" color="secondary">
                      Save
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EditQuestions;
