import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function TagSelector({ Tags, value, onChange }) {
  // Ensure value is always an array and contains valid tag objects
  const sanitizedValue = React.useMemo(() => {
    if (!Array.isArray(value)) return [];
    return value?.filter((tag) => tag && tag.name);
  }, [value]);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={Tags}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      value={value}
      onChange={(event, newValue) => {
        const validTags = newValue.filter((tag) => tag && tag.name);
        onChange(validTags);
      }}
      renderOption={(props, option, { selected }) => {
        if (!option?.name) return null;

        const optionProps = { ...props };
        delete optionProps.key;

        return (
          <li key={option.id || option.name} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={sanitizedValue.some((val) => val.name === option.name)}
            />
            {option.name}
          </li>
        );
      }}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Category/Tags"
          placeholder="Select category"
        />
      )}
    />
  );
}
