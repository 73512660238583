import { Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import QuizIcon from "@mui/icons-material/Quiz";
import SegmentIcon from "@mui/icons-material/Segment";
import GroupsIcon from "@mui/icons-material/Groups";
import ElevatorIcon from "@mui/icons-material/Elevator";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { useDispatch, useSelector } from "react-redux";
import { getQaQueueById } from "./State/QaDuck";
import withAuth from "../../../../../Hoc/withAuth";
import DataCaptureEmailList from "./DataCaptureEmailList";

const QaQueuesSetup = ({ user }) => {
  // console.log(user);

  const navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQaQueueById(id));
  }, [dispatch, id]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueue } = Qa;

  const { pathname } = useLocation();

  const currentPath = pathname.split("/")[4];

  const Paths = [
    {
      name: "QA Queue",
      location: "qaQueue",
      icon: <SegmentIcon />,
    },
    {
      name: "QA Queue Teams",
      location: "teams",
      icon: <GroupsIcon />,
    },

    {
      name: "Question And Answers Edit",
      location: "QuestionAndAnswers",
      icon: <QuizIcon />,
    },
    {
      name: "Add Questions",
      location: "AddExistingQuestionToQueue",
      icon: <QuizIcon />,
    },
    {
      name: "QA Evaluations",
      location: "Evaluations",
      icon: <ElevatorIcon />,
    },

    {
      name: "Move Evaluations",
      location:
        user.userType === "admin" ? "MoveEvaluationsAdmin" : "MoveEvaluations",
      icon: <OpenWithIcon />,
    },
    {
      name: "Data Capture Email List",
      location: "DataCaptureEmailList",
      icon: <DataCaptureEmailList />,
    },
  ];

  return (
    <>
      <PageHeader
        title="QA Queues Setup"
        subheader="Create and manage queues for QA"
        breadcrumbs={[
          {
            title: "Home",
            href: "/",
          },
          {
            title: "QA Console",
            href: "/CDA/QaConsole/QaQueues",
          },
          {
            title: "QaQueuesSetup",
            href: `/CDA/QaQueuesSetup/${id}`,
          },
        ]}
      />
      {/* <AlertPopup open={CreateQEvalSuccess} message="Evaluation Created" /> */}

      <Grid container>
        {Paths.map((path, index) => {
          return (
            <Grid key={index} item xs={3}>
              <Card
                sx={
                  currentPath === path.location
                    ? { borderStyle: "solid", borderColor: "primary.main" }
                    : null
                }>
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 5,
                  }}
                  onClick={() => {
                    navigate(`${path.location}`);
                  }}>
                  <Typography color="textPrimary">{path.name}</Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Typography align="center" variant="h6" sx={{ mt: 4 }}>
        {qaQueue?.queue_name}
      </Typography>
      <Stack sx={{ mt: 4 }}>
        <Outlet />
      </Stack>
    </>
  );
};

export default withAuth(QaQueuesSetup);
