import React, { useState } from "react";
import withAuth from "../../../../../../Hoc/withAuth";
import PageHeader from "../../../../../../Components/Bits/PageHeader";
import Reinitialize from "../../Admin/Evaluations/components/Reinitialize";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Grid, LinearProgress } from "@mui/material";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import CallRecordings from "./CallRecordings";
import Notes from "./Notes";
import ViewEvaluationMetrics from "../../Admin/Evaluations/components/viewEvaluationMetrics";
import EvaluationsCard from "./EvaluationsCard";

const ViewEvaluationQAUser = ({ user }) => {
  const { id, evalID } = useParams();
  const [showForm, setShowForm] = useState(false);
  const [callListeningTime, setCallListeningTime] = useState(0);

  const updateCallListeningTime = (time) => {
    setCallListeningTime(time);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getCallRecordings = useQuery([`Recordings${evalID}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`
    );
  });

  const Recordings = getCallRecordings.data?.data?.data;

  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID", evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/${evalID}`,
        config
      ),
  });

  const evaluation = getEvaluationByID.data?.data?.data;

  const evaluationsWithCreatedBy = evaluation?.history?.filter(
    (evaluation) => evaluation.createdBy
  );

  const lastCreatedByEvaluation =
    evaluationsWithCreatedBy?.[evaluationsWithCreatedBy.length - 1];

  const emailOfLastEvaluation =
    lastCreatedByEvaluation?.createdBy === user.email;

  const completedEvaluation = evaluation?.status === "Completed";

  const updateEvaluation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/evaluations/${evalID}`,
        data,
        config
      ),
    onSuccess: () => {
      getEvaluationByID.refetch();
      setShowForm(false);
    },
  });

  const startEvaluation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/startEvaluation`,
        data
      ),
    onSuccess: () => {
      setShowForm(true);
    },
  });

  const endEvaluation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/endEvaluation`,
        data
      ),
    onSuccess: () => {
      getEvaluationByID.refetch();
      setShowForm(false);
    },
  });

  const AutoSaveEvaluation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/endEvaluation`,
        data
      ),
    onSuccess: () => {
      getEvaluationByID.refetch();
    },
  });

  const handleStartEvaluation = () => {
    startEvaluation.mutate(evaluation);
  };

  if (getEvaluationByID.isLoading) {
    return <LinearProgress />;
  }

  if (getEvaluationByID.isError) {
    return (
      <div>Error loading evaluation: {getEvaluationByID.error.message}</div>
    );
  }

  return (
    <div>
      <PageHeader
        title="QA Inbound User"
        subheader="Manage your QA Inbound evaluations"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound User", href: "/CDA/inbound/user" },
        ]}
      />
      <Grid container height={1} spacing={2}>
        <Grid item xs={12}>
          <EvaluationsCard evaluation={evaluation} />
        </Grid>
        <Grid item xs={12}>
          <Notes evaluation={evaluation} updateEvaluation={updateEvaluation} />
        </Grid>
      </Grid>

      <CallRecordings
        callListeningTime={callListeningTime}
        updateCallListeningTime={updateCallListeningTime}
        evaluation={evaluation}
        showForm={showForm}
      />

      {evaluation && (
        <>
          {(!showForm &&
            (evaluation.status === "New" || evaluation.status === "Busy")) ||
          completedEvaluation ||
          (!showForm && !completedEvaluation) ? (
            <Button
              variant="contained"
              color={evaluation.status === "Busy" ? "warning" : "success"}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
              onClick={handleStartEvaluation}
              disabled={
                startEvaluation.isLoading ||
                completedEvaluation ||
                evaluation.status === "Failed"
                  ? true
                  : false ||
                    (evaluation.status === "Failed" &&
                      emailOfLastEvaluation === false) ||
                    (Recordings?.length === 0 && evaluation.Link === null) ||
                    evaluation.status === "Done"
              }>
              {startEvaluation.isLoading
                ? "Starting..."
                : evaluation.status === "New"
                ? "Start Evaluation"
                : evaluation.status === "Busy"
                ? "Continue"
                : evaluation.status === "Failed" ||
                  evaluation.status === "Re-Evaluation Requested" ||
                  evaluation.status === "Dispute Requested" ||
                  completedEvaluation
                ? "Redo Evaluation "
                : "Start Evaluation"}
            </Button>
          ) : (
            <Reinitialize
              user={user}
              setStart={setShowForm}
              endEvaluation={endEvaluation}
              AutoSaveEvaluation={AutoSaveEvaluation}
              evaluation={evaluation}
              callListeningTime={callListeningTime}
            />
          )}
        </>
      )}

      {(evaluation.status === "Completed" ||
        evaluation.status === "Failed" ||
        evaluation.status === "Re-Evaluation Requested" ||
        evaluation.status === "Dispute Requested" ||
        evaluation.status === "Done") && <ViewEvaluationMetrics />}

      <AlertPopup
        open={updateEvaluation.isSuccess}
        message="Evaluation Updated"
        severity="warning"
      />

      <AlertPopup
        open={endEvaluation.isSuccess}
        message={"Success"}
        severity={"success"}
      />
    </div>
  );
};

export default withAuth(ViewEvaluationQAUser);
