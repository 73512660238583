import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import {
  MenuItem,
  Select,
  Stack,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const languages = [
  "English",
  "Afrikaans",
  "isiZulu",
  "SeSotho",
  "Tswana",
  "Sepedi",
  "TshiVenda",
  "isNdebele",
  "siSwati",
  "isiXhosa",
  "XiTsonga",
];

const userTypeMap = {
  10: "QA Analyst",
  20: "Call Center Team Leader",
  30: "Manager",
};

const userTypeIdMap = {
  "QA Analyst": 10,
  "Call Center Team Leader": 20,
  "Manager": 30,
};

export default function EditTeamMember({ editMember, row }) {
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);

  const handleClickOpen = () => {
    setSelectedRow(row);

    if (row && typeof row.languages === "string") {
      const parsedLanguages = row.languages
        .split(",")
        .filter((lang) => lang.trim() !== "");
      setSelectedLanguages(parsedLanguages);
    } else if (row && Array.isArray(row.languages)) {
      setSelectedLanguages(row.languages);
    } else {
      setSelectedLanguages([]);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // // function to get the initial user_type value
  const getInitialUserType = (row) => {
    if (!row) return "";

    if (userTypeIdMap[row.user_type]) {
      return userTypeIdMap[row.user_type];
    }

    return "";
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>
        <EditIcon color="primary" />
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogTitle>Edit Team Member</DialogTitle>
          {selectedRow ? (
            <Formik
              initialValues={{
                user_type: getInitialUserType(selectedRow),
                email: selectedRow.email || "",
              }}
              validationSchema={Yup.object({
                user_type: Yup.number().required("User type is required"),
              })}
              onSubmit={(values) => {
                if (selectedRow && selectedRow.id) {
                  const payload = {
                    id: selectedRow.id,
                    user_type:
                      userTypeMap[values.user_type] || values.user_type,
                    languages: selectedLanguages,
                  };

                  editMember.mutate(payload);
                  handleClose();
                } else {
                  console.error("Missing team member ID");
                  alert("Error: Missing team member ID. Please try again.");
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Stack p={2} spacing={2}>
                    {/* <Typography variant="body2">
                      ID: {selectedRow.id || "Not available"}
                    </Typography> */}

                    <FormControl fullWidth>
                      <InputLabel htmlFor="email-field">Email</InputLabel>
                      <Select
                        id="email-field"
                        value={values.email}
                        label="Email"
                        disabled
                      >
                        <MenuItem value={values.email}>{values.email}</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="select-user-type-label">
                        User Type
                      </InputLabel>
                      <Select
                        labelId="select-user-type-label"
                        id="select-user-type"
                        value={values.user_type}
                        onChange={(e) =>
                          setFieldValue("user_type", e.target.value)
                        }
                        label="User Role"
                      >
                        <MenuItem value={10}>QA Analyst</MenuItem>
                        <MenuItem value={20}>Call Center Team Leader</MenuItem>
                        <MenuItem value={30}>Manager</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="multiple-language-label">
                        Languages
                      </InputLabel>
                      <Select
                        labelId="multiple-language-label"
                        id="multiple-language-select"
                        multiple
                        value={selectedLanguages}
                        onChange={(e) => setSelectedLanguages(e.target.value)}
                        label="Languages"
                      >
                        {languages?.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    p={2}
                  >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="primary" variant="contained" type="submit">
                      Save Changes
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          ) : (
            <Typography color="error">No row data available</Typography>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
