import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Typography,
  IconButton,
  Switch
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";


const EditQuestionGroup = ({ editQuestionGroups, group}) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Edit Group">
      <IconButton variant="contained" onClick={handleClickOpen} color={group.active ? "primary" : "primary"}
        disabled={!group.active}
        >
        <EditIcon />
      </IconButton>
      </Tooltip>
      

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle> QA Question Group</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: group.id || "",
              name: group.name || "",
              description: group.description || "",
              group_type: group.group_type || "",
              campaign_specific: group.campaign_specific || false,
              campaign_id: id,
              active: group.active,
            }}
            enableReinitialize = {true}
            onSubmit={(values) => {
              console.log(values);
              editQuestionGroups.mutate(values);
              handleClose();
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Stack sx={{ py: 2 }} spacing={2}>
                  <TextfieldWrapper name="name" label="Name" />
                  <TextfieldWrapper name="description" label="Description" />
                  {/* <FormControl fullWidth>
                    <InputLabel>Group Question Type</InputLabel>
                    <Field
                      as={Select}
                      name="group_type"
                      label="Group Question Type"
                      value={values.group_type}
                      onChange={(event) =>
                        setFieldValue("group_type", event.target.value)
                      }
                    >
                      <MenuItem value="Score">Score Group</MenuItem>
                      <MenuItem value="Text Input">Text Input Group</MenuItem>
                      <MenuItem value="Yes/No">Yes / No Group</MenuItem>
                    </Field>
                  </FormControl> */}

                  <Box
                    sx={{
                      border: "0.1px solid #ccc",
                      borderRadius: "5px",
                      padding: "16px",
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Mark the Question Group as Campaign Specific
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography>No</Typography>
                      <Switch
                        checked={values.campaign_specific}
                        onChange={(event) =>
                          setFieldValue("campaign_specific", event.target.checked)
                        }
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Box>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button onClick={handleClose}>cancel</Button>
                  <Button variant="contained" type="submit" color="secondary">
                    save
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EditQuestionGroup;
