import React from "react";
import CreateEvaluations from "./components/CreateEvaluations";
import { Stack, Button, LinearProgress, Chip } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../../Hoc/withAuth";

const EvaluationsMain = ({ user }) => {
  const { id } = useParams();
  const [viewCompleted, setViewCompleted] = React.useState(false);
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getAllEvaluations = useQuery({
    queryKey: ["getAllEvaluations"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/evaluations`,
        config
      ),
  });

  // Fetch rows
  const rows = getAllEvaluations.data?.data?.data || [];

  const busyEvaluations = rows.filter(
    (row) => row.status !== "Completed" && row.status !== "Done"
  );

  const completedEvaluations = rows.filter(
    (row) => row.status === "Completed" || row.status === "Done"
  );

  if (getAllEvaluations?.isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  const columns = [
    {
      field: "View",
      headerName: "View",
      renderCell: (params) => {
        const evalID = params.row.id;

        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(`/CDA/inbound/admin/${id}/Evaluations/${evalID}/`);
            }}>
            View
          </Button>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            color={
              params.row.status === "Completed"
                ? "secondary"
                : params.row.status === "Busy"
                ? "warning"
                : params.row.status === "New" ||
                  params.row.status === "Re-Evaluate"
                ? "primary"
                : params.row.status === "Failed"
                ? "error"
                : "info"
            }
          />
        );
      },
    },
    {
      field: "unique_id",
      headerName: "Unique_ID",
      width: 200,
      valueGetter: (params) => ` ${params.row.unique_id}`,
    },
    {
      field: "QA Consultant",
      headerName: "QA Consultant",
      width: 200,
      valueGetter: (params) =>
        `${params.row.evaluation_qa_users?.name} ${params.row.evaluation_qa_users?.surname}`,
    },
    {
      field: "CC Agent",
      headerName: "CC Agent",
      width: 200,
      valueGetter: (params) =>
        ` ${params.row.evaluation_users?.name} ${params.row.evaluation_users?.surname}`,
    },
    {
      field: "campaign_linking",
      headerName: "QA Campaign Name",
      width: 200,
      valueGetter: (params) => params.row.campaign?.name || "N/A",
    },
    {
      field: "language",
      headerName: "Language",
      width: 150,
      valueGetter: (params) => params.row.language || "N/A",
    },

    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      valueGetter: (params) => {
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString("en-GB");
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      valueGetter: (params) => {
        const date = new Date(params.row.updatedAt);
        return date.toLocaleDateString("en-GB");
      },
    },
  ];

  return (
    <div>
      <Stack
        sx={{ mb: 3, mt: 3 }}
        direction="row"
        justifyContent="space-between">
        <CreateEvaluations getAllEvaluations={getAllEvaluations} />
      </Stack>
      <Stack sx={{ height: "60vh" }}>
        <DataGridPremium
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowId={(row) => row.id}
          rows={busyEvaluations}
          columns={columns}
        />
      </Stack>
      <Stack>
        <Button
          onClick={() => {
            setViewCompleted(!viewCompleted);
          }}>
          View Completed Evaluations
        </Button>
      </Stack>
      <Outlet />
      {viewCompleted && (
        <Stack sx={{ height: "60vh" }}>
          <DataGridPremium
            rows={completedEvaluations} // Display completed evaluations
            columns={columns}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Stack>
      )}
    </div>
  );
};

export default withAuth(EvaluationsMain);
