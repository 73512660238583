import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import * as Yup from "yup";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

export default function CreateTag({createTag}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <React.Fragment>
      <Button  sx={{width:'100%'}} variant="contained" onClick={handleClickOpen}>
        Create Tag
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create a new Tag</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
            })}
            onSubmit={(values) => {
              console.log(values);
              createTag.mutate(values);
              handleClose();
            }}
          >
            {() => {
              return (
                <Form>
                  <Stack sx={{ py: 2, width: "550px" }} spacing={2}>
                    <TextfieldWrapper name="name" label="Queue Name" />
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      cancel
                    </Button>
                    <Button variant="contained" type="submit" color="secondary">
                      save
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <AlertPopup
        open={createTag.isSuccess}
        message="Tag created successfully"
        severity={"success"}
      />
      <AlertPopup
        open={createTag.isError}
        message="Error creating Tag"
        severity={"error"}
      />
    </React.Fragment>
  );
}
