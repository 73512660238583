import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import withAuth from "../../../../../Hoc/withAuth";
import CreateDataCaptureEmail from "./Components/DataCaptureEmailsList/CreateDataCaptureEmail";
import DeleteDataCaptureEmail from "./Components/DataCaptureEmailsList/DeleteDataCaptureEmail";
import EditDataCaptureEmail from "./Components/DataCaptureEmailsList/EditDataCaptureEmail";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const DataCaptureEmailList = ({ user }) => {
  const { id } = useParams();

  const GetDataCaptureList = useQuery({
    queryKey: ["DataCaptureEmailList", id],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/dataCaptureEmailList`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    },
  });

  const createDataCapEmail = useMutation(
    async (values) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/dataCaptureEmailList`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        GetDataCaptureList.refetch();
      },
    }
  );

  const updateDataCapEmail = useMutation(
    async (values) => {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/dataCaptureEmailList/${values.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        GetDataCaptureList.refetch();
      },
    }
  );

  const deleteDataCapEmail = useMutation(
    async (values) => {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/dataCaptureEmailList/${values.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        GetDataCaptureList.refetch();
      },
    }
  );

  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,

      renderCell: (params) => {
        return (
          <Stack direction="row">
            <EditDataCaptureEmail
              id={id}
              data={params.row}
              updateDataCapEmail={updateDataCapEmail}
            />
            <DeleteDataCaptureEmail
              id={id}
              data={params.row}
              deleteDataCapEmail={deleteDataCapEmail}
            />
          </Stack>
        );
      },
    },
  ];

  console.log("GetDataCaptureList", GetDataCaptureList);

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ mb: 2 }}>
            <CreateDataCaptureEmail
              id={id}
              createDataCapEmail={createDataCapEmail}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack sx={{ height: "70vh" }}>
            <DataGridPremium
              getRowId={(row) => row.id}
              rows={GetDataCaptureList.data?.data || []}
              columns={columns}
              loading={GetDataCaptureList.isLoading}
            />
          </Stack>
        </Grid>
      </Grid>
      <AlertPopup
        open={createDataCapEmail.isSuccess}
        message="Data Capture Email Created"
        severity="success"
      />

      <AlertPopup
        open={updateDataCapEmail.isSuccess}
        message="Data Capture Email Updated"
        severity="success"
      />

      <AlertPopup
        open={deleteDataCapEmail.isSuccess}
        message="Data Capture Email Deleted"
        severity="success"
      />

      <AlertPopup
        open={createDataCapEmail.isError}
        message="Data Capture Email Not Created"
        severity="error"
      />

      <AlertPopup
        open={updateDataCapEmail.isError}
        message="Data Capture Email Not Updated"
        severity="error"
      />
    </Stack>
  );
};

export default withAuth(DataCaptureEmailList);
