import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

export default function RemoveTag({ removeTag, tag }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} color="error">
        Remove
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create QA Inbound Campaign</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ id: tag.id, name: tag.name }}
            onSubmit={(values) => {
              console.log(values);
              removeTag.mutate(values);
              handleClose();
            }}
          >
            {() => {
              return (
                <Form>
                  <Typography variant="h6" gutterBottom>
                    {tag.name}
                  </Typography>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      cancel
                    </Button>
                    <Button color="error" variant="contained" type="submit">
                      Delete
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <AlertPopup
        open={removeTag.isSuccess}
        message="Tag removed successfully"
        severity={"success"}
      />
      <AlertPopup
        open={removeTag.isError}
        message="Error removing Tag"
        severity={"error"}
      />
    </React.Fragment>
  );
}
