import React from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Stack,
  Link,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "auto",
  display: "flex",
  flexDirection: "column",
};

const AddMembers = ({ open, onClose, onSave, existingEmails }) => {
  const [selectedUser, setSelectedUser] = React.useState(null);

  const getAllUserswithModule = useQuery({
    queryKey: ["Notificatinsgroup"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Notificatinsgroup/dd`
      ),
  });

  const data = getAllUserswithModule?.data?.data?.data;

  const handleSave = () => {
    const data = {
      email: selectedUser.email,
      user_id: selectedUser.id,
    };

    onSave(data);
    onClose();
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const filteredMembers =
    existingEmails &&
    existingEmails?.length > 0 &&
    data?.filter((member) => !existingEmails.includes(member.email));

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <Stack spacing={2}>
            <Typography id="modal-title" variant="h6" component="h2">
              Add Team Member to Notifications Group
            </Typography>
            <Autocomplete
              id="campaign-autocomplete"
              options={filteredMembers || []}
              getOptionLabel={(option) => option.email || ""}
              onChange={(_, newValue) => setSelectedUser(newValue)}
              renderInput={(params) => <TextField {...params} label="Search" />}
              sx={{ width: "101%", ml: 2 }}
              isOptionEqualToValue={(option, value) =>
                option.email === value.email
              }
            />

            <Link
              color="inherit"
              sx={{ pointerEvents: "auto", cursor: "pointer" }}
              underline="hover"
              onClick={() => {
                navigate(`/CDA/inbound/admin/${id}/Teams`);
              }}>
              Can't find the user you are looking for? Add then to the Queue
              Team
            </Link>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddMembers;
