import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../../Hoc/withAuth";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OverView = ({ user }) => {
  const token = user.token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const GetOverView = useQuery({
    queryKey: ["OverView"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/targets/overView`,
        config
      ),
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (GetOverView.isLoading) return <div>Loading...</div>;

  let rowData = GetOverView.data?.data?.data;

  let data =
    rowData &&
    rowData.length > 0 &&
    rowData?.flatMap((campaign) =>
      campaign.cc_campaign_month_groups.flatMap((group) =>
        group.cc_campaign_planning_months.map((planning) => {
          let contacts_per_month =
            planning.lead_no * (planning.contact_ra / 100) || 0;

          let target_sales =
            contacts_per_month * (planning.target_sal / 100) || 0;

          let target_sales_per_leads_ratio =
            planning.lead_no > 0 ? (target_sales / planning.lead_no) * 100 : 0;

          let target_hours =
            target_sales * (planning.target_sales_per_hour || 0);

          let forecast_business_income_per_sale = planning.pricing_mo
            ? planning.forecast_a * planning.pricing_mo
            : planning.forecast_a;

          let forecast_business_income_for_hours =
            (planning.lead_no / 10) * 120;

          let forecast_theoretical_business_income =
            target_sales * (forecast_business_income_per_sale || 0);

          let forecast_commission_income =
            target_sales * (planning.ci_per_sal || 0);

          let qa_target = target_sales * (planning.qa_rate / 100) || 0;

          return {
            id: planning.id,
            campaignName: campaign.name,
            description: campaign.description,
            active: campaign.active ? "Active" : "Inactive",
            monthGroup: `${group.month} ${group.year}`,
            monthName: planning.month_name,
            forecastType: planning.type,
            leadNo: planning.lead_no ?? "N/A",
            contactRate: planning.contact_ra ?? "N/A",
            targetSales: planning.target_sal ?? "N/A",
            forecastAmount: planning.forecast_a ?? "N/A",
            ciPerSale: planning.ci_per_sal ?? "N/A",
            pricingModel: planning.pricing_mo ?? "N/A",
            qaRate: planning.qa_rate ?? "N/A",
            businessImpact: planning.business_i ?? "N/A",
            contacts_per_month,
            target_sales,
            target_sales_per_leads_ratio,
            target_hours,
            forecast_business_income_per_sale,
            forecast_business_income_for_hours,
            forecast_theoretical_business_income,
            forecast_commission_income,
            qa_target,
          };
        })
      )
    );

  const columns = [
    { field: "campaignName", headerName: "Campaign Name", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "active", headerName: "Status", width: 120 },
    {
      field: "monthGroup",
      headerName: "Month Group",
      width: 150,
      groupable: true,
    },
    { field: "monthName", headerName: "Month Name", width: 120 },
    { field: "forecastType", headerName: "Forecast Type", width: 150 },
    { field: "leadNo", headerName: "Lead Number", width: 120 },
    { field: "contactRate", headerName: "Contact Rate", width: 120 },
    { field: "targetSales", headerName: "Target Sales", width: 120 },
    { field: "forecastAmount", headerName: "Forecast Amount", width: 150 },
    { field: "ciPerSale", headerName: "CI Per Sale", width: 120 },
    { field: "pricingModel", headerName: "Pricing Model", width: 120 },
    { field: "qaRate", headerName: "QA Rate", width: 120 },
    { field: "businessImpact", headerName: "Business Impact", width: 150 },
    {
      field: "contacts_per_month",
      headerName: "Contacts Per Month",
      width: 150,
    },
    { field: "target_sales", headerName: "Target Sales", width: 150 },
    {
      field: "target_sales_per_leads_ratio",
      headerName: "Target Sales Per Leads Ratio",
      width: 200,
    },
    { field: "target_hours", headerName: "Target Hours", width: 150 },
    {
      field: "forecast_business_income_per_sale",
      headerName: "Forecast Business Income Per Sale",
      width: 250,
    },
    {
      field: "forecast_business_income_for_hours",
      headerName: "Forecast Business Income For Hours",
      width: 250,
    },
    {
      field: "forecast_theoretical_business_income",
      headerName: "Forecast Theoretical Business Income",
      width: 300,
    },
    {
      field: "forecast_commission_income",
      headerName: "Forecast Commission Income",
      width: 250,
    },
    { field: "qa_target", headerName: "QA Target", width: 150 },
  ];

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        OverView
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
              Overview
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            initialState={{
              rowGrouping: {
                model: ["campaignName", "monthGroup", "monthName"],
              },
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default withAuth(OverView);
