import React from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { Stack, Button, Typography, Chip, LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import withAuth from "../../../../../Hoc/withAuth";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

const InboundUserHome = ({ user }) => {
  const [viewCompleted, setViewCompleted] = React.useState(false);
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: evaluationData, isLoading } = useQuery(
    ["getAllEvaluations"],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/`,
        config
      );
      return response?.data?.data || [];
    },
    {
      onError: (error) => {
        console.error("Error fetching evaluations:", error);
      },
    }
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  const validRows = evaluationData || [];

  const busyEvaluations =
    validRows &&
    validRows.length > 0 &&
    validRows?.filter(
      (row) => row.status !== "Completed" && row.status !== "Done"
    );
  const completedAndFailedEvaluations =
    validRows &&
    validRows.length > 0 &&
    validRows?.filter(
      (row) => row.status === "Completed" || row.status === "Done"
    );

  const columns = [
    {
      field: "View",
      headerName: "Action",
      renderCell: (params) => {
        const evalID = params.row.id;
        const id = params.row.campaign_id;
        const buttonLabel = params.row.status === "Busy" ? "Continue" : "View";

        return (
          <Button
            variant={params.row.status === "Busy" ? "text" : "text"}
            color={params.row.status === "Busy" ? "warning" : "primary"}
            onClick={() => {
              navigate(`/CDA/inbound/user/${id}/${evalID}`);
            }}>
            {buttonLabel}
          </Button>
        );
      },
    },
    {
      field: "unique_id",
      headerName: "Unique_ID",
      width: 200,
      valueGetter: (params) => ` ${params.row.unique_id}`,
    },
    {
      field: "campaign_linking",
      headerName: "QA Campaign Name",
      width: 200,
      valueGetter: (params) => params.row.campaign?.name || "N/A",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            color={
              params.row.status === "Completed"
                ? "secondary"
                : params.row.status === "Busy"
                ? "warning"
                : params.row.status === "New" ||
                  params.row.status === "Re-Evaluate"
                ? "primary"
                : params.row.status === "Failed"
                ? "error"
                : "info"
            }
          />
        );
      },
    },
    {
      field:"language",
      headerName: "Language",
      width: 200,
      valueGetter: (params) => params.row.language || "N/A"
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => {
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString();
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      valueGetter: (params) => {
        const date = new Date(params.row.updatedAt);
        return date.toLocaleDateString();
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title="QA Inbound User"
        subheader="Manage your QA Inbound evaluations"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound User", href: "/CDA/inbound/user" },
        ]}
      />
      <Stack
        sx={{ mb: 2 }}
        direction="row"
        spacing={2}
        justifyContent="space-between">
        <Typography variant="h6">Evaluations</Typography>
        <Button
          startIcon={<QueryStatsIcon />}
          onClick={() => {
            navigate(`/CDA/inbound/user/Statistics`);
          }}>
          View Stats
        </Button>
      </Stack>
      <Stack sx={{ height: "100vh" }}>
        <DataGridPremium
          slots={{
            toolbar: GridToolbar,
          }}
          columns={columns}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowId={(row) => row.id}
          rows={busyEvaluations}
          initialState={{
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "asc" }],
            },
          }}
        />
      </Stack>
      <Stack>
        <Button
          onClick={() => {
            setViewCompleted(!viewCompleted);
          }}>
          View Completed Evaluations
        </Button>
      </Stack>

      {viewCompleted && (
        <Stack sx={{ height: "100vh" }}>
          <DataGridPremium
            rows={completedAndFailedEvaluations}
            columns={columns}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Stack>
      )}
    </div>
  );
};

export default withAuth(InboundUserHome);
